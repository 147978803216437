'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import personActions from 'actions/PersonActionCreators';
import modalActions from 'actions/ModalActionCreators';

import WizardModal from 'components/base/WizardModal';
import ConfirmModalContent from 'components/utils/ConfirmModalContent';


const propTypes = {
    notifications: PropTypes.array.isRequired,
};

const defaultProps = {};

const NotificationsModalContent = (props) => {

    const onSyncChangesConfirmationCallback = () => {
        personActions.setLastSeenNotificationDate();
    };

    const onClose = () => {
        modalActions.showModal(
            <ConfirmModalContent
                title="Ok, I get it."
                onConfirmCallback={onSyncChangesConfirmationCallback}
                buttonText="Don't show these messages again"
                cancelButtonText="Show again next time I sign in"
            />
        );
    }; 

    const sections = [];
    props.notifications.forEach(el => {
        el.LineItems.forEach(item => {
            const content = item.Paragraphs.map((para, i) => <p key={"para" + i} dangerouslySetInnerHTML={{__html:para}} />);
            sections.push({
                content: <div>{content}</div>,
                heading: <div dangerouslySetInnerHTML={{__html:item.Heading}} />,
            });
        });
    });

    return (
        <WizardModal
            sections={sections}
            header="What's New"
            onCloseCallback={onClose}
            showCloseButton
        />
    );
};

NotificationsModalContent.propTypes = propTypes;
NotificationsModalContent.defaultProps = defaultProps;

export default NotificationsModalContent;
