'use strict';

import {EventEmitter} from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';
import processAction from 'services/storeUtils';
import _ from 'underscore';

var _storeStatuses = {
    error: null,
    success: false,
    loading: false,
};
var _permissions;
var _rolePermissions = null;

var permissionsLoaded = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _permissions = {
            resources: {},
        };        
        _.each(responseBody, function (el) {
            if (!_permissions.resources[el.Resource.ResourceType]) {
                _permissions.resources[el.Resource.ResourceType] = {
                    resource: el.Resource,
                    roles: {},
                };
            }            
            _permissions.resources[el.Resource.ResourceType].roles[el.RoleId] = {
                Name: el.Role.Name,
                [el.Role.RoleType]: el.AccessLevel,
            };            
        }); 
    });
};

var getPermissionsByRole = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _rolePermissions = responseBody;
    });
};


var PermissionsStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        this.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE, callback);
    },
    addChangeByRoleListener(callback) {
        this.on(appConstants.events.CHANGE_PERMISSION_BY_ROLE, callback);
    },
    removeChangeByRoleListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERMISSION_BY_ROLE, callback);
    },
    getPermissions() {
        var response = _storeStatuses;
        response.permissions = _permissions;
        return response;
    },
    getPermissionsByRole() {
        var response = _storeStatuses;
        response.permissions = _rolePermissions;
        return response;
    },
});

PermissionsStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {
        case appConstants.actions.PERMISSIONS.GET_PERMISSIONS:
            permissionsLoaded(action);
            PermissionsStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PERMISSIONS.GET_PERMISSIONS_BY_ROLE:
            getPermissionsByRole(action);
            PermissionsStore.emit(appConstants.events.CHANGE_PERMISSION_BY_ROLE);
            break;

        default:
            return true;
    }

    return true;

});

export default PermissionsStore; 
