'use strict';

import constants from 'constants/appConstants';
import Spinner from 'services/vendors/fastSpinner/fast-spinner';
import 'services/vendors/fastSpinner/fast-spinner.css';

var SpinnerManager = {
    spinners: {},
    mainSpinner: null,

    startMainSpinner(id) {
        this.spin(id, constants.spinner_options.main.color);
    },

    stopMainSpinner(id) {
        this.stop(id);
    },

    spin(id, colour) {
        if (colour === undefined) {
            colour = constants.spinner_options.small.color;
        }

        if (!document.querySelector('#' + id)) {
            return null;
        }

        this.spinners[id] = new Spinner({
            id,
            color: colour,
            width: "2px",
        });
        this.spinners[id].show();
    },

    stop(id) {
        if (this.spinners[id]) {
            this.spinners[id].hide();
        }
    },
};

export default SpinnerManager;
