'use strict';

import React from 'react';

import classNames from 'classnames';

import 'styles/SubMenu.scss';


class Item extends React.Component {
    render() {
        return (
            <li id={this.props.id ? this.props.id : null} className={this.props.className}>
                {this.props.children}
            </li>
        );
    }
}


class SubMenu extends React.Component {
    render() {
        var classes = classNames(
            'sub-nav',
            this.props.className,
            {
                'show': this.props.visible,
                'no-width': this.props.contentWidth,
            }
        );

        return (
            <ul id={this.props.id ? this.props.id : null} className={classes}>
                {this.props.children}
            </ul>
        );
    }
}

export { SubMenu, Item }; 