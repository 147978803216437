'use strict';

import React from 'react';

const ModalFooter = function (props) {
    return (
        <div className="mc-footer">
            {props.children}
        </div>                        
    );
};

export default ModalFooter; 