'use strict';

import React from 'react';

import classNames from 'classnames';

const ModalBody = function (props) {
    const classes = classNames(
        'mc-body', props.className
    );

    return (
        <div id={props.id ? props.id : null} className={classes}>
            {props.children}
        </div>
    );
};

export default ModalBody; 