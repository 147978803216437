'use strict';

import React from 'react';
import modalActions from 'actions/ModalActionCreators';
import AvatarEdit from 'components/base/AvatarEdit';


class AvatarFileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            avartarFileInput  : '',
        };

        this.handleFile = this.handleFile.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.id !== this.props.id;
    }

    handleFile(event) {
        var reader = new FileReader();
        var file = event.target.files[0];

        if (!file) {
            return;
        }

        reader.onload = function (img) {
            var content = (
                <AvatarEdit
                    {...this.props}
                    picture={img.target.result}
                    personId={this.props.profile.Id}
                />
            );

            modalActions.showModal(content);

            this.setState({avartarFileInput: ''});

        }.bind(this);
        reader.readAsDataURL(file);
    }

    render() {
        return (
            <input
                id="AvatarFileInput"
                value={this.state.avartarFileInput}
                type="file"
                accept="image/*"
                onChange={this.handleFile}
                style={{display: "none"}}
            />
        );
    }
}

export default AvatarFileUpload;
