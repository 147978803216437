'use strict';

import ReactGA from 'react-ga';
import constants from 'constants/appConstants';
import moment from 'moment';
import utils from 'services/utils';

var _requests = [];
var _currentModal = null;
var _trackingID = constants.gaTrackingId;

var AnalyticsManager = {

    constants:{
        categories: {
            APPLY_FOR_LEAVE: 'LeaveApply',
            NOT_SIGNED_IN: 'NotSignedIn',
            MODAL: 'Modal',
        },

        actions: {
            ATTACHMENT: 'ATTACHMENT',
            AUTO: 'AUTO',
            BUTTON: 'BUTTON',
            CHECK_BOX_SELECTION: 'CHECK_BOX_SELECTION',
            CLOSE_MODAL: 'CLOSE_MODAL',
            DATE_PICKER: 'DATE_PICKER',
            DROP_DOWN: 'DROP_DOWN',
            LOOK_UP: 'LOOK_UP',
            RADIO_SELECTION: 'RADIO_SELECTION',
            SUBMIT: 'SUBMIT',
            TEXT_AREA: 'TEXT_AREA',
            TEXT_FIELD: 'TEXT_FIELD',
        },

        labels: {
            FORECAST_BALANCE: 'FORECAST_BALANCE',
            START_DATE: 'START_DATE',
            END_DATE: 'END_DATE',
            LEAVE_REASON: 'LEAVE_REASON',
            LEAVE_HOURS: 'LEAVE_HOURS',
            COMMENTS: 'COMMENTS',
        },
    },

    initialize(debug = false) {

        if (this.isAnalyticsEnabled()) {

            if (debug === true) {
                ReactGA.initialize(_trackingID, {
                    debug: true,
                    gaOptions: {
                        cookieDomain: "none",
                    },
                });
            } else {
                ReactGA.initialize(_trackingID);
            }

            //Override titles to hide identifiable information
            ReactGA.set({title:"ESS"}); 
        }
    },

    isAnalyticsEnabled() {
        return _trackingID !== undefined && _trackingID !== null;
    },

    pageView(page) {

        function removeParam(key, sourceURL) {
            let rtn = sourceURL.split("?")[0];
            let param;
            let params_arr = [];
            const queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
            if (queryString !== "") {
                params_arr = queryString.split("&");
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + "?" + params_arr.join("&");
            }
            return rtn;
        }

        if (this.isAnalyticsEnabled()) {
            let pagePath = page.replace(/[0-9]/g, "X");


            pagePath = removeParam('_k', pagePath);

            ReactGA.pageview(pagePath);
        }
    },

    modalView(modalName) {
        if (this.isAnalyticsEnabled()) {
            _currentModal = modalName;
            ReactGA.modalview(modalName);
        }
    },

    request(key, response, error) {
        if (this.isAnalyticsEnabled()) {

            if (_requests[key]) {

                if (response === constants.request.PENDING && _requests[key].response === constants.request.PENDING) {
                    // Start again
                    _requests[key] = {response, requestDate: moment(new Date(),"DD/MM/YYYY HH:mm:ss")};

                } else {

                    var requestTime = moment.utc(moment(new Date(),"DD/MM/YYYY HH:mm:ss")).diff(_requests[key].requestDate).valueOf();
                    var variable = "No response";
                  
                    if (typeof response === 'object') {
                        variable = 'SUCCESS';
                    } else if (response === 'ERROR') {
                        variable = '{0}-{1}'.format(response, error);

                        ReactGA.exception({
                            description: '{0} {1}'.format(key, utils.slugify(error)),
                            fatal: false,
                        });

                    } else if (response) {
                        variable = response;
                    }

                    ReactGA.timing({
                        category: key,
                        variable,
                        value: requestTime,
                    });

                    delete _requests[key];
                }

            } else if (response === constants.request.PENDING) {

                _requests[key] = {response, requestDate: moment(new Date(), "DD/MM/YYYY HH:mm:ss")};
            }
        }
    },

    event(category, action, label = '', value = null, nonInteraction = false) {
        if (this.isAnalyticsEnabled()) {

            if (category === null && action === 'CLOSE_MODAL') {
                category = _currentModal;
                _currentModal = null;
            }

            if (value !== null) {
                ReactGA.event({
                    category,
                    action,
                    label,
                    value,
                    nonInteraction,
                });
            } else {
                ReactGA.event({
                    category,
                    action,
                    label,
                    nonInteraction,
                });
            }
        }
    },

};

export default AnalyticsManager;
