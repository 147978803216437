'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'styles/Column.scss';


const propTypes = {
    highlight: PropTypes.bool,
    narrow: PropTypes.bool,
    error: PropTypes.bool,
    noPadding: PropTypes.bool,
    double: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
};

class Column extends React.Component {

    render() {
        var classes = classNames(
            'gc-grid-cell',
            this.props.className, {
                'highlight': this.props.highlight,
                'narrow': this.props.narrow,
                'error': this.props.error,
                'no-padding': this.props.noPadding,
                'double': this.props.double,
            }
        );

        return (
            <div id={this.props.id} className={classes}>
                {this.props.children}
            </div>
        );
    }
}

Column.propTypes = propTypes;

export default Column; 
