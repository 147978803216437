'use strict';

import { EventEmitter } from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';

var _isOpen = false;
var _content;
var _className = null;
var _escapeEnabled = true;
var _loading = false;


/**
 * Modal is opened
 */
var modalOpened = function (action) {
    _isOpen = true;
    _content = action.data;
    _className = action.className ? action.className : null;
    _loading = false;
};

/**
 * Disable escape button to close Modal. Is useful for when content inside the modal needs the escape button eg. A drop down select
 */
var disableEscapeButton = function () {
    _escapeEnabled = false;
};

/**
 * Enable escape button to close Modal. is enabled by default but you may need to re-enable after disabling
 */
var enableEscapeButton = function () {
    _escapeEnabled = true;
};

/**
 * Page is closed
 */
var modalClosed = function () {
    _isOpen = false;
    _content = null;
    _className = null;
    _escapeEnabled = true;
    _loading = false;
};

var modalLoading = function (action) {
    _loading = action.loading;
};


var ModalStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        this.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE, callback);
    },
    getModalState() {
        return {
            isOpen: _isOpen,
            content: _content,
            className: _className,
            isEscapeEnabled: _escapeEnabled,
            loading: _loading,
        };
    },
});

ModalStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
    case appConstants.actions.MODAL.OPEN:
        modalOpened(action);
        break;

    case appConstants.actions.MODAL.DISABLE_ESCAPE:
        disableEscapeButton();
        break;

    case appConstants.actions.MODAL.ENABLE_ESCAPE:
        enableEscapeButton();
        break;

    case appConstants.actions.MODAL.CLOSE:
        modalClosed();
        break;

    case appConstants.actions.MODAL.REFRESH:
        break;

    case appConstants.actions.MODAL.LOADING:
        modalLoading(action);
        break;
            
    default:
        return true;
    }

    ModalStore.emit(appConstants.events.CHANGE);
    return true;

});

export default ModalStore; 
