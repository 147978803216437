'use strict';

import { EventEmitter } from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';

var _isLoggedIn = false;
var _user;
var _signInError = false;

/**
 * Page is Loaded
 */
var pageLoaded = function (data) {
    _isLoggedIn = data?.user !== null;
    _user = data?.user;
    _signInError = data?.signInError;
};


var AuthStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        this.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE, callback);
    },
    addLoginChangeListener(callback) {
        this.on(appConstants.events.LOGIN, callback);
    },
    removeLoginChangeListener(callback) {
        this.removeListener(appConstants.events.LOGIN, callback);
    },
    getLoggedInUserData() {
        return {
            isLoggedIn: _isLoggedIn,
            user: _user,
            signInError: _signInError,    
        };
    },
});

AuthStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {

        case appConstants.actions.PAGE_LOADED:
            pageLoaded(action.data);
            AuthStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.LOGIN:
            AuthStore.emit(appConstants.events.LOGIN);
            break;

        default:
            return true;
    }

    return true;
});

export default AuthStore; 
