'use strict';

import _ from 'underscore';
import constants from 'constants/appConstants';


export default class Permissions {
    constructor(permissions) {
        this.permissions = permissions;
    }

    setPermissions(permissions) {
        this.permissions = permissions;
    }

    hasPermissions(resource, roleType, accessLevels, scopes) {
        if (this.permissions && this.permissions.resources[resource]) {                  
            let match;

            if (roleType) {
                match = _.find(this.permissions.resources[resource].roles, function (value) {
                    return accessLevels.indexOf(value[roleType]) >= 0;
                }.bind(this));
                
                return match !== undefined;              
            } else if (scopes) {
                
                match = _.find(scopes, function (scope) {
                    if (scope.RoleType === constants.ENUMS.ROLE_TYPES_MAP.Admin) {
                        roleType = scope.RoleType;
                    } else {
                        roleType = constants.ENUMS.ROLE_TYPES_MAP[scope.RoleType];
                    }
                    
                    // special case: Default roleType returning from back-end
                    if (roleType && roleType === constants.response_codes.person_scope.default) {
                        let isDefault = _.find(this.permissions.resources[resource].roles, function (value) {
                            return accessLevels.indexOf(value[roleType]) >= 0;
                        }.bind(this));
                        return isDefault !== undefined;

                    } else if (roleType) {
                        if (this.permissions.resources[resource].roles[scope.Id]) {
                            return accessLevels.indexOf(this.permissions.resources[resource].roles[scope.Id][roleType]) >= 0; 
                        }                        
                    }
                }.bind(this));
                
                return match !== undefined;

            } else {                
                match = _.find(this.permissions.resources[resource].roles, function (value) {  
                    const arrValue = _.values(value); 
                    return accessLevels.indexOf(arrValue[1]) >= 0;
                });
                return match !== undefined;
            }
        }
        return false;
    }
    
    hasAllPermissions(resource, roleType, accessLevels) {
        if (this.permissions && this.permissions.resources[resource] && roleType) {
            if (roleType ===  constants.ENUMS.ROLE_TYPES_MAP.Manager) {
                let match = _.find(this.permissions.resources[resource].roles, function(value) {
                    let rt = Object.keys(value)[1];
                    if (rt === constants.ENUMS.ROLE_TYPES_MAP.Manager) {
                        return accessLevels.indexOf(value[roleType]) < 0;
                    }                
                }.bind(this));
                return match === undefined;
            } else {
                let match = _.find(this.permissions.resources[resource].roles, function(value) {
                        return accessLevels.indexOf(value[roleType]) >= 0;                                    
                }.bind(this));
                return match !== undefined;
            }
            
        }
        return false;
    }

    isVisible(resource, roleType, scopes) {        
        return this.hasPermissions(resource, roleType, ['Edit', 'View'], scopes);
    }

    isEditable(resource, roleType, scopes) {
        return this.hasPermissions(resource, roleType, ['Edit'], scopes);
    }

    // new logic to validate a role type which has multiple roles if having all visible permission, e.g. manager for time being
    isAllVisible(resource, roleType) {
        return this.hasAllPermissions(resource, roleType, ['Edit', 'View']);
    }

    isAllEditable(resource, roleType) {
        return this.hasAllPermissions(resource, roleType, ['Edit']);
    }
}
