'use strict';

import Api from 'services/api';

var WorkflowActionCreators = {
    getWorkflowScopes() {
        Api.WORKFLOWS.getWorkflowScopes();
    },
    getUserWorkflowScopes() {
        Api.WORKFLOWS.getUserWorkflowScopes();
    },
    getSingleWorkflowScope(id) {
        Api.WORKFLOWS.getSingleWorkflowScope(id);
    },
    addWorkflow(data) {
        Api.WORKFLOWS.addWorkflow(data);
    },
    updateWorkflow(data) {
        Api.WORKFLOWS.updateWorkflow(data).then(function () {
            WorkflowActionCreators.getSingleWorkflowScope(data.Id);
        });
    },
};

export default WorkflowActionCreators;