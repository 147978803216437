'use strict';

import dataContext from 'services/dataContext';
import Dispatcher from 'dispatcher/TngAppDispatcher';
import constants from 'constants/appConstants';

import AnalyticsManager from 'services/analyticsManager';

const _pendingRequests = {};
const _cachedResults = {};

function abortPendingRequests(key) {
    if (_pendingRequests[key]) {
        _pendingRequests[key]._callback = function () {};
        if (_pendingRequests[key].abort) {
            _pendingRequests[key].abort();
        }
        _pendingRequests[key] = null;
    }
}

function dispatch(key, response, params, error) {

    AnalyticsManager.request(key, response, error);
    
    var payload = {actionType: key, data: response};
    if (params) {
        payload.queryParams = params;
    }
    if (error) {
        payload.error = error;
    }
    Dispatcher.handleAction(payload);
}

// return successful response, else return request Constants
function makeDigestFun(key, params, cacheKey, cacheResult = false) {
    return function (err, res) {
        if (err && err.timeout === constants.request.TIMEOUT_PERIOD) {
            dispatch(key, constants.request.TIMEOUT, params);
        } else if (err) {
            dispatch(key, constants.request.ERROR, params, err);
        } else if (res.status === 400 || !res.ok) {
            dispatch(key, constants.request.ERROR, params);
        } else {
            if (cacheResult) {
                _cachedResults[cacheKey || key] = res;
            }
            dispatch(key, res, params);
        }
    };
}

var ApiManager = {
    getRequest(key, url, queryParams, anonymous, acceptType, custom_timeout) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, queryParams);
        _pendingRequests[key] = dataContext.get(url, queryParams, anonymous, acceptType, custom_timeout).end(
            makeDigestFun(key, queryParams)
        );
        return _pendingRequests[key];        
    },
    getCachedRequest(cacheKey, actionKey, url, queryParams, anonymous, acceptType, custom_timeout) {
        abortPendingRequests(actionKey);
        dispatch(actionKey, constants.request.PENDING, queryParams);
        const key = cacheKey || actionKey;
        if (!_cachedResults[key]) {
            _cachedResults[key] = constants.request.PENDING;
            _pendingRequests[actionKey] = dataContext.get(url, queryParams, anonymous, acceptType, custom_timeout).end(
                makeDigestFun(actionKey, queryParams, key, true)
            );
        } else {
            dispatch(actionKey, _cachedResults[key], queryParams);
        }
        return _pendingRequests[actionKey];        
    },
    getArrayBuffer(key, url, queryParams, acceptType, fileName) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, queryParams);
        _pendingRequests[key] = dataContext.getArrayBuffer(url, queryParams, acceptType, fileName).then(
            function (e) {
                dispatch(key, e.target, queryParams);
            }
        );
        return _pendingRequests[key]; 
    },
    putRequest(key, url, params, anonymous) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        _pendingRequests[key] = dataContext.put(url, params, anonymous).end(
            makeDigestFun(key, params)
        );
        return _pendingRequests[key];
    },
    postRequest(key, url, params, anonymous, custom_timeout) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        _pendingRequests[key] = dataContext.post(url, params, anonymous, custom_timeout).end(
            makeDigestFun(key, params)
        );
        return _pendingRequests[key];
    },
    patchRequest(key, url, params, anonymous) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        _pendingRequests[key] = dataContext.patch(url, params, anonymous).end(
            makeDigestFun(key, params)
        );
        return _pendingRequests[key];
    },
    delRequest(key, url, id, anonymous) {
        var params = {id};
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        _pendingRequests[key] = dataContext.del(url, id, anonymous).end(
            makeDigestFun(key, params)
        );
        return _pendingRequests[key];
    },
    mockSuccessfulRequest(key, params, response = "Here is the response", delay = 2000) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        setTimeout(function () {
            dispatch(key, response, params);   
        }, delay);
    },
    mockTimeoutRequest(key, params, delay = 2000) {
        abortPendingRequests(key);
        dispatch(key, constants.request.PENDING, params);
        setTimeout(function () {
            dispatch(key, constants.request.TIMEOUT, params);
        }, delay);
    },
};

export default ApiManager;
