'use strict';

import Api from 'services/api';

var PermissionsActionCreators = {

    getPermissions() {
        Api.PERMISSIONS.getPermissions();
    },
    getPermissionsByRole(roleId) {
        Api.PERMISSIONS.getPermissionsByRole(roleId);
    },
    updateRolePermissions(roleId, model) {
        Api.PERMISSIONS.updateRolePermissions(roleId, model).then(() => {
            PermissionsActionCreators.getPermissions();    
        });
    },
};

export default PermissionsActionCreators; 
