'use strict';

import Api from 'services/api';
import moment from 'moment';

var PersonActionCreators = {
    getPeople(orderBy, skip) {
        Api.PEOPLE.getPeople(orderBy, skip);
    },
    getMorePeople(nextPageLink) {
        Api.PEOPLE.getMorePeople(nextPageLink);
    },
    getMoreFilteredPeople(nextPageLink) {
        Api.PEOPLE.getMoreFilteredPeople(nextPageLink);
    },
    getFilteredPeople(lookupString, top, skip, managersOnly) {
        Api.PEOPLE.getFilteredPeople(lookupString, top, skip, managersOnly);
    },
    getSinglePerson(id, reset) {
        if (reset === undefined) {
            reset = false;
        }
        Api.PEOPLE.getSinglePerson(id, reset);
    },
    getSinglePersonByWorkflowItemId: (id, reset) => {
        Api.PEOPLE.getSinglePersonByWorkflowItemId(id, reset);
    },
    addPerson(person) {
        Api.PEOPLE.addPerson(person).then(function () {
            PersonActionCreators.getPeople();
        });
    },
    editPerson(person) {
        Api.PEOPLE.updatePerson(person);
    },
    removePerson(id) {
        Api.PEOPLE.removePerson(id).then(function () {
            PersonActionCreators.getPeople();
        });
    },
    getSinglePersonName(personId) {
        Api.PEOPLE.getSinglePersonName(personId);
    },
    updateName(personId, model) {
        Api.PEOPLE.updateName(personId, model).then(function () {
            PersonActionCreators.getSinglePersonName(personId);
        }).then(function () {
            PersonActionCreators.getSinglePerson(personId);
        });
    },
    getSinglePersonDemographic(personId) {
        Api.PEOPLE.getSinglePersonDemographic(personId);
    },
    updateDemographic(personId, model) {
        Api.PEOPLE.updateDemographic(personId, model).then(function () {
            PersonActionCreators.getSinglePersonDemographic(personId);
        });
    },
    getSinglePersonAddressContacts(personId) {
        Api.PEOPLE.getSinglePersonAddressContacts(personId);
    },
    updateAddressContact(personId, addressDetails) {
        Api.PEOPLE.updateAddressContact(personId, addressDetails).then(function () {
            PersonActionCreators.getSinglePersonAddressContacts(personId);
        });
    },
    getSinglePersonMedicalConditions(personId) {
        Api.PEOPLE.getSinglePersonMedicalConditions(personId);
    },
    updateMedicalConditions(personId, model) {
        Api.PEOPLE.updateMedicalConditions(personId, model).then(function () {
            PersonActionCreators.getSinglePersonMedicalConditions(personId);
        });
    },
    getSinglePersonWorkContacts(personId) {
        Api.PEOPLE.getSinglePersonWorkContacts(personId);
    },
    updateSinglePersonWorkContacts(personId, model) {
        Api.PEOPLE.updateSinglePersonWorkContacts(personId, model).then(function () {
            PersonActionCreators.getSinglePersonWorkContacts(personId);
        }).then(() => {
            PersonActionCreators.getSinglePerson(personId);
        });
    },
    getSinglePersonPersonalContacts(personId) {
        Api.PEOPLE.getSinglePersonPersonalContacts(personId);
    },
    updateSinglePersonPersonalContacts(personId, model) {
        Api.PEOPLE.updateSinglePersonPersonalContacts(personId, model).then(function () {
            PersonActionCreators.getSinglePersonPersonalContacts(personId);
        }).then(() => {
            PersonActionCreators.getSinglePerson(personId);
        });
    },
    getSinglePersonEmailContacts(personId) {
        Api.PEOPLE.getSinglePersonEmailContacts(personId);
    },
    updateDefaultEmailContact(personId, contactType, personRefId) {
        Api.PEOPLE.updateDefaultEmailContact(personId, contactType).then(function () {
            Api.PERSON.getPersonIdentity(personId).then(function () {
                PersonActionCreators.getPersonActivationInfo(personRefId);
            });
        });
    },
    getSinglePersonBanks(personId) {
        Api.PEOPLE.getSinglePersonBanks(personId);
    },
    updateSinglePersonBanks(personId, model) {
        Api.PEOPLE.updateSinglePersonBanks(personId, model).then(function () {
            PersonActionCreators.getSinglePersonBanks(personId);
        }).catch(function () {
            PersonActionCreators.getSinglePersonBanks(personId);
        });
    },
    getSinglePersonEmergencyContacts(personId) {
        Api.PEOPLE.getSinglePersonEmergencyContacts(personId);
    },
    updateSinglePersonEmergencyContacts(personId, model) {
        Api.PEOPLE.updateSinglePersonEmergencyContacts(personId, model).then(function () {
            PersonActionCreators.getSinglePersonEmergencyContacts(personId);
        });
    },
    removePersonEmergencyContacts(id) {
        Api.PEOPLE.removePersonEmergencyContacts(id).then(function () {             
            //PersonActionCreators.getSinglePerson(id);
        });
    },
    getSinglePersonLeave(personId, model) {
        Api.PEOPLE.getSinglePersonLeave(personId, model);
    },
    getSinglePersonLeaveTraces(personId, appId) {
        Api.PEOPLE.getSinglePersonLeaveTraces(personId, appId);
    },
    addSinglePersonLeave(personId, model) {
        Api.PEOPLE.addSinglePersonLeave(personId, model);
    },
    editSinglePersonLeave(personId, model) {
        Api.PEOPLE.editSinglePersonLeave(personId, model);
    },
    cancelSinglePersonLeave(personId, model) {
        Api.PEOPLE.cancelSinglePersonLeave(personId, model);
    },
    getSinglePersonLeaveReasons(personId) {
        Api.PEOPLE.getSinglePersonLeaveReasons(personId);
    },
    updateSinglePersonLeaveReasons(personId, model) {
        Api.PEOPLE.updateSinglePersonLeaveReasons(personId, model);
    },
    getSinglePersonLeaveBalances(personId, date, leaveTypeId, incFutureLeave) {
        Api.PEOPLE.getSinglePersonLeaveBalances(personId, date, leaveTypeId, incFutureLeave);
    },
    getSinglePersonLeaveBalanceValidation(personId, leaveApp) {
        Api.PEOPLE.getSinglePersonLeaveBalanceValidation(personId, leaveApp);        
    },
    getSinglePersonPayAdvices(personId, model) {
        Api.PEOPLE.getSinglePersonPayAdvices(personId, model);
    },
    getSinglePersonPaySummaries(personId, model) {
        Api.PEOPLE.getSinglePersonPaySummaries(personId, model);
    },
    getSinglePersonPayAdvicePdf(personId, model) {
        Api.PEOPLE.getSinglePersonPayAdvicePdf(personId, model);
    },
    getSinglePersonPaySummaryPdf(personId, model) {
        Api.PEOPLE.getSinglePersonPaySummaryPdf(personId, model);
    },
    activateIdentity(personId, personRefId) {
        Api.PERSON.activateIdentity(personId).then(function () {
            Api.PEOPLE.getSinglePerson(personId, false).then(function () {
                Api.PERSON.getPersonIdentity(personId).then(function () {
                    PersonActionCreators.getPersonActivationInfo(personRefId);
                });
            });
        });
    },
    getLoginPerson() {
        Api.PERSON.getLoginPerson();
    },
    loginPerson() {
        Api.PERSON.loginPerson();
    },
    updateLastLoginDateTime(personId, lastLoginDateTime) {        
        Api.PERSON.updateLastLoginDateTime(personId, lastLoginDateTime);        
    },    
    getPersonIdentity(personId, personRefId) {
        Api.PERSON.getPersonIdentity(personId).then(function () {
            PersonActionCreators.getPersonActivationInfo(personRefId);
        });
    },
    closePersonIdentity(personId, personRefId) {
        Api.PERSON.closePersonIdentity(personId).then(function () {
            PersonActionCreators.getPersonIdentity(personId, personRefId);
        });
    },
    closeCurrentPersonIdentity() {
        Api.PERSON.closeCurrentPersonIdentity();
    },
    reopenPersonIdentity(personId, personRefId, unlockAccountAction) {
        Api.PERSON.reopenPersonIdentity(personId, unlockAccountAction).then(function () {
            PersonActionCreators.getPersonIdentity(personId, personRefId);
        });
    },
    updateUsername(personId, username, personRefId) {
        Api.PERSON.updateUsername(personId, username).then(function () {
            PersonActionCreators.getPersonIdentity(personId, personRefId);
        });
    },   
    cancelActivation(personRefId) {
        Api.PERSON.cancelActivation(personRefId).then(function () {
            PersonActionCreators.getPersonActivationInfo(personRefId);
        });
    },
    sendIdentityPasswordReset(personId) {
        Api.PERSON.sendResetIdentityPassword(personId);
    },
    getPersonActivationInfo(personRefId) {
        Api.PERSON.getPersonActivationInfo(personRefId);
    },
    resetMFA(personId, personRefId) {
        Api.PERSON.resetMFA(personId).then(function () {
            PersonActionCreators.getPersonIdentity(personId, personRefId);
        });
    },
    getSinglePersonWorkflowScope(personId, workflowId, timeNow) {
        Api.PEOPLE.getSinglePersonWorkflowScope(personId, workflowId, timeNow);
    },
    getSinglePersonEmployment(personId) {
        Api.PEOPLE.getSinglePersonEmployment(personId);
    },
    getSinglePersonEmployer(personId) {
        Api.PEOPLE.getSinglePersonEmployer(personId);
    },
    getSinglePersonSalary(personId) {
        Api.PEOPLE.getSinglePersonSalary(personId);
    },
    getSinglePersonTax(personId) {
        Api.PEOPLE.getSinglePersonTax(personId);
    },
    getSinglePersonTfn(personId) {
        Api.PEOPLE.getSinglePersonTfn(personId);
    },
    getSinglePersonRolesTeams(personId) {
        Api.PEOPLE.getSinglePersonRolesTeams(personId);
    },
    getSinglePersonAssociatedTeams(personId) {
        Api.PEOPLE.getSinglePersonAssociatedTeams(personId);
    },
    getSinglePersonSuperannuation(personId) {
        Api.PEOPLE.getSinglePersonSuperannuation(personId);
    },
    getAvatar(personId, size) {
        Api.PEOPLE.getAvatar(personId, size);
    },
    updateAvatar(personId, model) {
        Api.PEOPLE.updateAvatar(personId, model).then(function () {
            PersonActionCreators.getSinglePerson(personId);
        });
    },
    deleteAvatar(personId) {
        Api.PEOPLE.deleteAvatar(personId).then(function () {
            PersonActionCreators.getSinglePerson(personId);
        });
    },
    getSinglePersonWorkDaysHours(personId, startDate, endDate, startSession, endSession) {
        Api.PEOPLE.getSinglePersonWorkDaysHours(personId, startDate, endDate, startSession, endSession);
    },
    getDelegateApprover(id) {
        Api.PEOPLE.getDelegateApprover(id);
    },
    editDelegateApprover(model) {
        Api.PEOPLE.editDelegateApprover(model).then(function () {
            PersonActionCreators.getDelegateApprover(model.OriginalPersonId);
        });
    },
    deleteDelegateApprover(id) {
        Api.PEOPLE.deleteDelegateApprover(id).then(function () {
            PersonActionCreators.getDelegateApprover(id);
        });
    },
    getMyDelegatedRedirects(id) {
        Api.PEOPLE.getMyDelegatedRedirects(id);
    },
    getSinglePersonApproverTypes(personId, timeNow) {
        Api.PEOPLE.getSinglePersonApproverTypes(personId, timeNow);  
    },
    getCompanyLogo() {
        Api.PERSON.getCompanyLogo();
    },
    uploadCompanyLogo(image) {
        Api.PERSON.uploadCompanyLogo(image).then(function (response) {
            PersonActionCreators.getCompanyLogo();
        });;
    },
    getNotifications() {
        Api.PERSON.getNotifications();
    },
    setLastSeenNotificationDate() {
        Api.PERSON.setLastSeenNotificationDate();
    },
    
    // T/S 
    getSinglePersonTimesheets(personId, model) {
        Api.PEOPLE.getSinglePersonTimesheets(personId, model);
    },
    updateSinglePersonTimesheets(personId, workflowId, model, isMultiAddMode) {
        Api.PEOPLE.updateSinglePersonTimesheets(personId, workflowId, model).then(function () {
            if (!isMultiAddMode)
                PersonActionCreators.getSinglePersonTimesheets(personId, moment(model.periodStartDate).format("YYYY-MM-DD"));
        }).catch(function () {
            if (!isMultiAddMode)
            PersonActionCreators.getSinglePersonTimesheets(personId, moment(model.periodStartDate).format("YYYY-MM-DD"));
        });
    },
    addSinglePersonTimesheets(personId, model) {
        Api.PEOPLE.addSinglePersonTimesheets(personId, model);
    },
    editSinglePersonTimesheets(personId, model) {
        Api.PEOPLE.editSinglePersonTimesheets(personId, model);
    },
    cancelSinglePersonTimesheets(personId, model) {
        Api.PEOPLE.cancelSinglePersonTimesheets(personId, model);
    },    
};

export default PersonActionCreators;
