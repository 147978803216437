'use strict';

import React from 'react';

import ModalHeader from 'components/base/ModalHeader';
import ModalBody from 'components/base/ModalBody';
import ModalFooter from 'components/base/ModalFooter';
import modalActions from 'actions/ModalActionCreators';

import appConstants from 'constants/appConstants';

class ModalErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }
  
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    handleCloseButtonClick = () => {
        modalActions.hideModal();
    }
  
    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <ModalHeader>
                        {appConstants.error.GENERIC_ERROR}
                    </ModalHeader>

                    <ModalBody>
                        {appConstants.error.GENERIC_ERROR_DESC}
                    </ModalBody>

                    <ModalFooter>
                        <button
                            className="button--primary"
                            type="submit"
                            onClick={this.handleCloseButtonClick}
                        >
                            Close
                        </button>
                    </ModalFooter>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }  
}

export default ModalErrorBoundary; 
