'use strict';

import Api from 'services/api';

var ApprovalsActionCreators = {

    getApprovals(workflowType) {
        Api.APPROVALS.getApprovals(workflowType);
    },

    getOtherApprovals(workflowType) {
        Api.APPROVALS.getOtherApprovals(workflowType);
    },

    approve(approval) {
        Api.APPROVALS.approve(approval);
    },

    decline(approval) {
        Api.APPROVALS.decline(approval);
    },

    getApprovalCount(workflowType) {
        Api.APPROVALS.getApprovalCount(workflowType);
    },
};

export default ApprovalsActionCreators; 


