'use strict';

import Api from 'services/api';

var ConfigOptionsActionCreators = {
    getOptions(apiKey) {
        Api.CONFIGURATION_OPTIONS.getOptions(apiKey);
    },
};

export default ConfigOptionsActionCreators;
