'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import utils from 'services/utils';
import constants from 'constants/appConstants';

const propTypes = {
    title: PropTypes.string,
    titleExtension: PropTypes.string,
};

class DocumentTitle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title ? this.props.title : constants.documentTitle;

        if (this.props.titleExtension) {
            title = '{0} - {1}'.format(constants.documentTitle, this.props.titleExtension);
        }

        if (title !== document.title) {
            document.title = title;
        }

        if (this.props.children) {
            return React.Children.only(this.props.children);
        } else {
            return null;
        }
    }
}

DocumentTitle.propTypes = propTypes;

export default DocumentTitle; 
