'use strict';

import {EventEmitter} from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';
import processAction from 'services/storeUtils';

import appConstants from '../constants/appConstants';
// var utils = require('services/utils');

var _optionSets = {};
// var _storeStatuses = {
//     error: null,
//     success: false,
//     loading: false,
// };

var getOptions = function (response) {
    const apiKey = response.queryParams.apiKey || 'default';
    if (!_optionSets[apiKey]) {
        _optionSets[apiKey] = {};
    }

    const actions = processAction(response, function (responseBody) {
        _optionSets[apiKey].options = responseBody;
    });

    _optionSets[apiKey].statuses = actions;
};

var ConfigOptionsStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        this.on(appConstants.events.CHANGE_CONFIGURATION_OPTIONS, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_CONFIGURATION_OPTIONS, callback);
    },

    getOptions(apiKey) {
        apiKey = apiKey || "default";
        return _optionSets[apiKey];
    },
});


ConfigOptionsStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {

    case appConstants.actions.CONFIGURATION_OPTIONS.GET_CONFIG_OPTIONS:
        getOptions(action);
        ConfigOptionsStore.emit(appConstants.events.CHANGE_CONFIGURATION_OPTIONS);
        break;

    default:
        return true;

    }

    return true;
});

export default ConfigOptionsStore;
