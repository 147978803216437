'use strict';

import React from 'react';
import ModalErrorBoundary from 'components/base/ModalErrorBoundary';
import FocusTrap from 'focus-trap-react';
import ModalLoader from 'components/base/ModalLoader';

import modalStore from 'stores/ModalStore';
import modalActions from 'actions/ModalActionCreators';
import classNames from 'classnames';

import utils from 'services/utils';

import 'styles/Modal.scss';



class Modal extends React.Component {
    state = {
        isOpen: false,
        isEscapeEnabled: true,
        loading: false,
    };

    componentWillMount() {
        modalStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        modalStore.removeChangeListener(this._onChange);
    }

    _onChange = () => {
        var modalState = modalStore.getModalState();
        this.setState(modalState, function () {
            if (this.state.isOpen) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
            this.adjustHeight();    
        });
    };

    adjustHeight = () => {
        if (this.refs.modalContent) {
            document.body.classList.add('modal');
            var el = this.refs.modalContent;

            var padding = (el.parentElement.clientHeight - el.clientHeight) / 2;

            if (padding < 5) {
                el.style.top = "50px";
                el.style.bottom = "50px";
                el.style.overflow = "auto";

            } else {
                el.style.top = padding + "px";
            }            
        } else {    
            document.body.classList.remove('modal');    
        }
    };

    handleKeyDown = (event) => {
        if (event.keyCode ===  27 && this.state.isEscapeEnabled) { // escape key
            utils.preventDefault(event);
            this.handleClose();
        }
    };

    handleClose = () => {
        modalActions.hideModal();
    };

    render() {


        if (this.state.isOpen) {
            
            var classes = classNames(this.state.className,
                {
                    loading: !this.state.content,
                }
            );

            return (
                <div id="modal">
                    <div id="modal-mask" />
                    <div id="modal-content" className={classes} ref="modalContent">
                        <FocusTrap
                            className="focus-trap-container"
                            focusTrapOptions={{
                                onDeactivate: this.unmountTrap,
                            }}
                        >
                            <div>
                                <ModalLoader loading={this.state.loading || !this.state.content} />
                                <ModalErrorBoundary>
                                    {this.state.content}
                                </ModalErrorBoundary>
                            </div>
                        </FocusTrap>
                    </div>                        
                </div>
            );
        }
        return <div />;
    }
}

export default Modal; 