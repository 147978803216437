'use strict';
import React from 'react';
import Spinner from 'components/base/Spinner';
import constants from 'constants/appConstants';

import 'styles/Loader.scss';

class Loader extends React.Component {
    render() {

        var styles = {
            loader: {
                display: this.props.loading ? 'block' : 'none',
            },
        };

        return (

            <div id="loader" style={styles.loader}>
                <div id="loader-mask"></div>
                <Spinner
                    display={this.props.loading}
                    id={constants.spinner_options.main.id}
                    isMainSpinner
                />
            </div>

        );
    }
}

export default Loader; 
