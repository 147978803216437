import _ from 'underscore';

(function () {
    'use strict';
    const str = [
        '|||||||||||   ||||||      ||||||   ',
        '||          ||      ||  ||      || ',
        '||         ||          ||          ',
        '||          |||         |||        ',
        '|||||||||||    ||||        ||||    ',
        '||                 |||         ||| ',
        '||                   ||          ||',
        '||          ||      ||  ||      || ',
        '|||||||||||   ||||||      ||||||   ',
    ];

    let output = "";
    _.each(str, (line) => {
        output += (line + "\r\n");
    });

    console.log(output);

})();