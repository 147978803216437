'use strict';

import _ from 'underscore';
import serviceUtils from 'services/utils';
import appConstants from 'constants/appConstants';

var utils = {
    getDefaultEmail(emailContacts) {
        return _.findWhere(emailContacts, {
            IsDefaultEmail: true,
        });
    },

    getTeamsList(person) {
        return _.map(person.Memberships, function (m) {
            return m.Team.Name;
        });
    },

    isEmployeeOfTeam(person, teamName) {
        var team = _.find(person.Memberships, function (m) {
            return (m.Team.Name === teamName && m.Role.RoleType === appConstants.ENUMS.ROLE_TYPE.EMPLOYEE);
        });
        if (team) {
            return true;
        }
    },

    getMembershipCountByRoleType(person, roleType) {
        var count = 0;
        _.each(person.Memberships, function (m) {
            if (m.Team.Name !== "Unassigned" && m.Role.RoleType === roleType) {
                count++;
            }
        });
        return count;
    },

    isManagerOfTeam(person, teamName) {
        var team = _.find(person.Memberships, function (m) {
            return (m.Team.Name === teamName && m.Role.RoleType === appConstants.ENUMS.ROLE_TYPES.MANAGER);
        });
        if (team) {
            return true;
        }
    },

    isAdmin(person) {
        try {
            return _.contains(person.ApproverTypes, appConstants.ENUMS.APPROVER_TYPES.ADMIN);
        } catch (err) {
            return false;
        }        
    },

    getFullName(person) {
        return "{0}{1}{2}".format(person.FirstName, person.PreferredName ? " (" + person.PreferredName + ") " : " ", person.LastName).trim(); 
    },

    getPreferredOrFirstName(person) {
        if (person.PreferredName) {
            return person.PreferredName;
        } else {
            return person.FirstName;
        }
    },

    getPosessiveDeterminer(loggedInPerson, person) {
        return loggedInPerson.Id === person.Id ? "Your" : (person.PreferredName || person.FirstName) + "'s";
    },

    getAvatarColour(id) {
        return appConstants.avatarColours[(id - 1) % appConstants.avatarColours.length];
    },

    getEmergencyContactPhones(emergencyContact) {
        var mobilePhone = null;
        var otherPhone = null;

        if (!emergencyContact) {
            return {}; 
        }

        if (emergencyContact.PhoneContacts) {
            _.each(emergencyContact.PhoneContacts, function (el) {
                if (el.ContactType === "TelephoneContact") {
                    otherPhone = el;
                } else if (el.ContactType === "MobileContact") {
                    mobilePhone = el;
                }
            });
        }

        return {
            mobile: mobilePhone,
            other: otherPhone,
        };
    },

    getOfficeNumber(phoneContacts) {
        var num = _.find(phoneContacts, function (pc) {
            return pc.ContactType === 'WorkPhone';
        });

        if (num && num.PhoneContact.PhoneNumber) {
            return serviceUtils.formatPhone(num.PhoneContact.PhoneNumber);
        }
        return  'n/a';
    },

    getWorkMobile(phoneContacts) {
        var num = _.find(phoneContacts, function (pc) {
            return pc.ContactType === 'WorkMobile';
        });

        if (num && num.PhoneContact.PhoneNumber) {
            return serviceUtils.formatPhone(num.PhoneContact.PhoneNumber);
        }
        return  'n/a';
    },

    getWorkEmail(emailContacts) {
        var email = _.find(emailContacts, function (ec) {
            return ec.ContactType === 'WorkEmail';
        });

        if (email && email.EmailContact.Email) {
            return email.EmailContact.Email;
        }
        return  null;
    },

    getTeams(roleType, memberships) {

        if (roleType && memberships) {
            
            var teams = [];

            _.map(memberships, function (membership) {
                if (membership.Role.RoleType === roleType && !membership.Team.IsDefaultTeam) {
                    teams.push(membership.Team);
                }
            
            });

            return _.sortBy(teams, 'Name');

        } else {
            return [];
        }
    },

    hasCountry(person, country) {
        if (person.Country && person.Country === country) {
            return true;
        }
        
        return false;
    },
};

export default utils;
