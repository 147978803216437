'use strict';

import React from 'react';
import { IndexLink } from 'react-router';

import mainLogoImageURL from '../../images/logo-ess-nav-003.png';

import 'styles/Header.scss';

const Header = (props) => {

    const navSlider = props.navSlider;

    let nav;
    let logoLink;

    if (document.querySelector('.app-bar') && !navSlider.appbarElement) {
        navSlider.appbarElement = document.querySelector('.app-bar');
    }

    if (props.userInfo.isLoggedIn) {

        const companyLogo = props.companyLogo.logo;
        if (props.userInfo.person) {
            let logoImage = null;

            if (!props.companyLogo.loading) {
                const companyName = props.userInfo.person.CompanyName;
                if (props.permissions && props.permissions.isVisible('CompanyLogo')) {
                    if (companyLogo) {
                        var imgString = "data:image/jpeg;base64," + companyLogo;
                        logoImage = <img width="" src={imgString} alt="Company logo" />;
                    } else {
                        logoImage = <span className="company-name">{companyName}</span>;
                    }
                }
            }

            logoLink = (
                <IndexLink to="/" activeClassName="active">
                    {logoImage}                        
                </IndexLink>
            );
        }

        nav = (
            <div>
                <button className="menu button--plain button--square">
                    <i className="icon-hamburger" />
                </button>
            </div>
        );

        if (document.querySelector('.menu') && !navSlider.menuBtn) {
            navSlider.menuBtn = document.querySelector('.menu');
            navSlider.menuBtn.addEventListener('click', navSlider.toggleMenu.bind(navSlider));
        }

    } else {
        nav = <button className="button " onClick={props.handleLogin}>Sign in</button>;
        logoLink = <img width="" src={mainLogoImageURL} alt="ESS" />;
    }

    return (
        props.userInfo?.mounted ? (
            <header className="app-bar promote-layer">
                <div className="app-bar-container">                    
                    <div className="logo">
                        {logoLink}                        
                    </div>                    
                    <div className="nav">
                        {nav}
                    </div>
                </div>
            </header>
        ) : null
    );
};

export default Header; 

