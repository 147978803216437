'use strict';

import React from 'react';

import ErrorBoundary from 'components/base/ErrorBoundary';
import ColumnRow from 'components/base/ColumnRow';
import Column from 'components/base/Column';
import StatusBar from  'components/base/StatusBar';

import classNames from 'classnames';

import 'styles/Page.scss';

import ErrorContent from 'components/not-found/ErrorContent';


const TopStatusBar = (props) => {
    return (
        <ColumnRow className="app-error">
            <Column className="ae-column" noPadding>
                {props.statusBar}
            </Column>
        </ColumnRow>            
    );
};

class Page extends React.Component {
    componentDidMount() {
        if (this.props.navSlider) {
            const navSlider = this.props.navSlider;
            navSlider.main = document.querySelector('main');
            if (navSlider.main) {
                navSlider.main.addEventListener('click', navSlider.closeMenu.bind(navSlider));
            }
        }
    }

    render() {

        var errorMsg = this.props.error ? (
            <TopStatusBar
                statusBar={(
                    <StatusBar
                        title={this.props.error.Message ? this.props.error.Message : this.props.error}
                        error
                    />
                )}
            />
        ) : '';

        var notifications = this.props.showInlineNotifications && this.props.inlineNotifications?.length > 0 ? (
            <>
                {this.props.inlineNotifications.map((currentValue, index) => (
                    <TopStatusBar
                        key={`inline-notification-${index}`}
                        statusBar={(
                            <StatusBar
                                title={currentValue?.LineItems[0]?.Heading}
                                content={currentValue?.LineItems[0]?.Paragraphs.join("<br>")}
                                info={currentValue?.DisplayTypeCategory === "info"}
                                warning={currentValue?.DisplayTypeCategory === "warning"}
                                error={currentValue?.DisplayTypeCategory === "error"}
                                success={currentValue?.DisplayTypeCategory === "success"}
                                plain={
                                    currentValue?.DisplayTypeCategory !== "info" &&
                                    currentValue?.DisplayTypeCategory !== "warning" &&
                                    currentValue?.DisplayTypeCategory !== "success" &&
                                    currentValue?.DisplayTypeCategory !== "error"
                                }
                                contentDangerouslySetInnerHTML
                            />
                        )}
                    />
                ))}
            </>
        ) : '';
    
        const classes = classNames(
            "container", 
            this.props.className, {
                full: this.props.permissions && this.props.permissions.isVisible('TopHeader') && this.props.isFull,
            }
        );

        return (
            <main className={classes}>
                <ErrorBoundary>
                    {errorMsg}
                    {notifications}
                    <div className="panel-body">
                        {this.props.permissions && this.props.permissions.isError ? (
                            <ErrorContent />        
                        ) : (
                            this.props.children
                        )}
                    </div>
                </ErrorBoundary>
            </main>
        );
    }
}

export default Page; 
