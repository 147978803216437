'use strict';

const NavSlider = function () {
    this.body = document.body;
    this.navdrawerContainer = null;
    this.appbarElement = null;
    this.menuBtn = null;
    this.main = null;
};

NavSlider.prototype.toggleMenu = function () {
    this.body.classList.toggle('open');
    this.appbarElement.classList.toggle('open');
    this.navdrawerContainer.classList.toggle('open');
    this.navdrawerContainer.classList.add('opened');
};

NavSlider.prototype.closeMenu = function () {
    this.body.classList.remove('open');
    this.appbarElement.classList.remove('open');
    this.navdrawerContainer.classList.remove('open');
};

NavSlider.prototype.isOpen = function () {
    return this.body.classList.contains('open');
};

export default NavSlider;
