import appSettings from "config/compiledConstants";

const constants = {
    apiBaseUrl: appSettings.API_BASE_URL,
    eApiBaseUrl: appSettings.E_API_BASE_URL,
    authConfig: {
        CLIENT_ID: appSettings.CLIENT_ID,
        POST_LOGOUT_REDIRECT_URI: appSettings.POST_LOGOUT_REDIRECT_URI,
        POST_LOGIN_REDIRECT_URI: appSettings.POST_LOGIN_REDIRECT_URI,
        CACHE_LOCATION: 'sessionStorage', // enable this for IE, as sessionStorage does not work for localhost.
        EXPIRE_OFFSET_SECONDS: 1200, // 20 minutes
        SIGNIN_AUTHORITY: appSettings.SIGNIN_AUTHORITY,
        SIGNUP_AUTHORITY: appSettings.SIGNUP_AUTHORITY,
        KNOWN_AUTHORITIES: appSettings.KNOWN_AUTHORITIES,
        B2C_SCOPES: appSettings.B2C_SCOPES,
        MSAL_LOG_LEVEL: appSettings.MSAL_LOG_LEVEL,
        TOKEN_RENEWAL_OFFSET_SECONDS: appSettings.TOKEN_RENEWAL_OFFSET_SECONDS,
    },
};

export default constants;
