'use strict';

import React from 'react';
import spinnerManager from 'services/spinnerManager';

import classNames from 'classnames';

import 'styles/Spinner.scss';


class Spinner extends React.Component {
    
    constructor(props) {
        super(props);
        this._updateSpinner = this._updateSpinner.bind(this);
    }

    componentDidMount() {
        this._updateSpinner();
    }

    componentDidUpdate() {
        this._updateSpinner();
    }

    _updateSpinner() {

        if (this.props.display && spinnerManager) {
            if (this.props.isMainSpinner) {
                spinnerManager.startMainSpinner(this.props.id);
            } else {
                spinnerManager.spin(this.props.id, this.props.color);
            }
        } else {
            if (this.props.isMainSpinner) {
                spinnerManager.stopMainSpinner(this.props.id);
            } else {
                spinnerManager.stop(this.props.id);
            }
        }
    }

    render() {

        const classes = classNames(
            'spinnerContainer', this.props.className
        );

        return (
            <div id={this.props.id} className={classes}></div>
        );
    }
}

export default Spinner; 