'use strict';

import appConstants from 'constants/appConstants';

var processAction = function (response, successCallback, overWriteError) {

    var success = false;
    var loading = false;
    var error = null;

    if (response.data === appConstants.request.PENDING) {
        loading = true;
    } else if (response.data === appConstants.request.TIMEOUT) {
        error = 'TIMEOUT';
    } else if (response.data === appConstants.request.ERROR) {
        if (overWriteError) {
            error = {
                Message: overWriteError,
            };
        } else {
            try {
                error = response.error.response.body;
            } catch (e) {
                error = {
                    Message: 'ERROR',
                };
            }

            if (!error) {
                if (response.error.response.statusCode) {
                    error = appConstants.error.STATUS_CODE[response.error.response.statusCode] || appConstants.error.GENERIC_ERROR;
                } else {
                    error = appConstants.error.GENERIC_ERROR;
                }
            } 
        }
        
    } else {
        if (successCallback) {
            if (response.data.body || response.data.body === 0) {
                successCallback(response.data.body);
            } else if (response.data) {
                successCallback(response.data);
            }   
        }
    }

    return {
        loading,
        success,
        error,
    };
};

export default processAction;
