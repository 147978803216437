'use strict';

import React from 'react';
import moment from 'moment';
import constants from 'constants/appConstants';
import _ from 'underscore';


String.prototype.format = function () {
    // usage example: 'Added {0} by {1} to your collection'.f(title, artist)
    var s = this;
    var i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
    }
    return s;
};

// add origin to window.location
if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

var utils = {
    deepClone(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    slugify(text) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    },
    serialize(form) {
        var field;
        var s = [];
        if (typeof form === 'object' && form.nodeName === "FORM") {
            var len = form.elements.length;
            for (var i = 0; i < len; i++) {
                field = form.elements[i];
                if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                    if (field.type === 'select-multiple') {
                        for (var j = form.elements[i].options.length - 1; j >= 0; j--) {
                            if (field.options[j].selected) {
                                s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                            }
                        }
                    } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                        s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
                    }
                }
            }
        }
        return s.join('&').replace(/%20/g, '+');
    },
    stringifyForm(formModel) {
        formModel = JSON.stringify(formModel);
        return formModel.replace(/(\"\s+)/g, "\"").replace(/(\s+\")/g, "\"");  // trim whitespace from start and end of any text fields
    },
    preventDefault(event) {
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            event.returnValue = false;
        }
    },
    formatTFN(tfn) {
        if (!tfn) {
            return null;
        }

        return '{0} {1} {2}'.format(tfn.slice(0, 3), tfn.slice(3, 6), tfn.slice(6, 9));

    },
    formatNumber(number, decimalPlaces, removeTrailingZeroes = false) {
        if (decimalPlaces === undefined) {
            decimalPlaces = 2;
        }
        const fixedNumber = number.toFixed(decimalPlaces);

        if (removeTrailingZeroes) {
            return parseFloat(fixedNumber);
        }

        return fixedNumber;
    },
    formatCurrency(amount, excludeDollarSign = false, includeCommas = true) {
        if (amount === undefined || amount === null) {
            return null;
        }

        const amountGreaterThanZero = amount >= 0;
        amount = parseFloat(amount).toFixed(2);

        // add commas - https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
        if (includeCommas) {
            amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        if (amountGreaterThanZero) {
            if (!excludeDollarSign) {
                amount = '${0}'.format(amount);
            }
        } else {
            if (!excludeDollarSign) {
                amount = '-${0}'.format(amount.replace("-", ""));
            } else {
                amount = '-{0}'.format(amount.replace("-", ""));
            }
        }

        return amount;

    },
    formatName(person) {
        if (!person) {
            return null;
        }

        return '{1} {2} {0}'.format(person.LastName, person.FirstName, person.MiddleName || '');
    },
    stripPhoneNumber(phone) {
        return phone.replace(/\D/g, '');
    },
    toFormatPhone(configOptions) {
        let formatPhoneNumber = true; 

        if (configOptions && configOptions.options && configOptions.options.profile && configOptions.options.profile.contactDetails) {
            formatPhoneNumber = configOptions.options.profile.contactDetails.formatPhoneNumber;
        }
        return formatPhoneNumber;
    },
    formatPhone(phone) {
        if (!phone) {
            return null;
        }

        phone = this.stripPhoneNumber(phone);
        var len = phone.length;

        if (len <= 8) {
            const part1 = phone.slice(0, len - 4);
            const part2 = phone.slice(len - 4);
            phone = '{0} {1}'.format(part1, part2);
        } else if (len <= 10) {
            const firstTwoDigits = phone.slice(0, 2);
            if (firstTwoDigits === "04" || firstTwoDigits === "05") { //mobile
                const part1 = phone.slice(0, len - 6);
                const part2 = phone.slice(len - 6, len - 3);
                const part3 = phone.slice(len - 3);
                phone = '{0} {1} {2}'.format(part1, part2, part3);
            } else { //landline
                const part1 = phone.slice(0, len - 8);
                const part2 = phone.slice(len - 8, len - 4);
                const part3 = phone.slice(len - 4);
                phone = '{0} {1} {2}'.format(part1, part2, part3);
            }
        } else {
            const lastNineDigits = phone.slice(len - 9);
            if (lastNineDigits[0] === "4" || lastNineDigits[0] === "5") { // international mobile
                const part1 = phone.slice(0, len - 9);
                const part2 = phone.slice(len - 9, len - 6);
                const part3 = phone.slice(len - 6, len - 3);
                const part4 = phone.slice(len - 3);
                phone = '(+{0}) {1} {2} {3}'.format(part1, part2, part3, part4);
            } else { //landline
                const part1 = phone.slice(0, len - 9);
                const part2 = phone.slice(len - 9, len - 8);
                const part3 = phone.slice(len - 8, len - 4);
                const part4 = phone.slice(len - 4);
                phone = '(+{0}) {1} {2} {3}'.format(part1, part2, part3, part4);
            }
        }

        return phone;
    },
    formatBSB(bsb,country) {
        if (!bsb) {
            return null;
        }

        var len = bsb.length;
        var formattedBsb = bsb;

        if (constants.regex.BSB.test(bsb)) {
            if (country && country === constants.countries.NZ) {
                //New Zealeand
                formattedBsb = '{0}-{1}'.format(bsb.slice(0, len - 4), bsb.slice(2));
            } else {
                //Australia
                formattedBsb = '{0}-{1}'.format(bsb.slice(0, len - 3), bsb.slice(3));
            }
        }
        return formattedBsb;
    },
    formatBankAccountNumber(accountNo,country) {
        if (!accountNo) {
            return null;
        }

        var len = accountNo.length;
        var formattedAccNo = accountNo;

        if (country && country === constants.countries.NZ && constants.regex.BANK_NZ_ACCOUNT_NUMBER.test(accountNo) && len > 7) {
            //New Zealeand
            formattedAccNo = '{0}-{1}'.format(accountNo.slice(0, 7), accountNo.slice(7));
        }

        return formattedAccNo;
    },
    formatMobile(phone) {
        return this.formatPhone(phone);
    },
    formatDate(_date, useTitles, format) {
        if (!_date) {
            return null;
        }

        var dayMapping = [
            "Today",
            "Yesterday",
            "2 days ago",
        ];

        var date = moment(_date);
        var now = moment();

        if (useTitles) {
            var d = date.format(constants.dates.formats.SERVER_POST_FORMAT);
            var dayDifference = now.diff(d, 'days');

            if (dayMapping[dayDifference]) {
                return dayMapping[dayDifference];
            }
        }

        if (format === undefined) {
            format = constants.dates.formats.FULL;
        }

        return date.format(format);
    },
    formatDateMinimal(date) {
        if (!date) {
            return null;
        }

        date = moment(date);

        return date.format(constants.dates.formats.MINIMAL);
    },
    formatTime(date, useTitles) {
        if (!date) {
            return null;
        }

        date = moment(date);

        if (useTitles) {
            return moment(date).fromNow();
        } else {
            return date.format(constants.dates.formats.TIME_PORTION);
        }
    },
    formatDateTime(date) {
        if (!date) {
            return null;
        }

        date = moment(date);

        return '{0} {1}'.format(this.formatDate(date), this.formatTime(date));
    },
    formatAddress(address) {
        if (!address) {
            return null;
        }

        const isDefaultCountry = !address.Country || address.Country.CountryName === "Australia" ? true : false;

        const line1 = '{0}'.format(address.AddressLine1);
        const line2 = address.AddressLine2 ? '\n{0}'.format(address.AddressLine2) : '';

        let line3 = '{0} {1} {2}'.format(address.Suburb, address.Postcode ? address.Postcode : "", isDefaultCountry ? (address.State ? address.State.StateCode : '') : address.StateName ? address.StateName : "");
        line3 = line3.trim();
        line3 = ((line1 || line2) && line3 ? '\n' : '') + line3;

        const line4 = address.Country ? '\n{0}'.format(address.Country.CountryName) : '';

        const formattedAddress =  '{0}{1}{2}{3}'.format(line1, line2, line3, line4);
        return formattedAddress.trim().replace(/ +(?= )/g,''); // remove duplaicte spaces
    },
    getWeekdayNames() {
        var weekdays = moment.weekdaysShort();
        weekdays.push(weekdays.shift());
        return weekdays;
    },
    createGroupedArray(arr, chunkSize) {
        var groups = [];
        var i;
        for (i = 0; i < arr.length; i += chunkSize) {
            groups.push(arr.slice(i, i + chunkSize));
        }
        return groups;
    },
    sort(collection, iteratee, sortOrder) {

        var sortedItems = _.sortBy(collection, function (i) {
            var val = iteratee(i);
            if (typeof (val) === 'string') {
                return val.toLowerCase();
            } else {
                return val;
            }
        });

        if (sortOrder === constants.sortOrders.DESCENDING) {
            sortedItems = sortedItems.reverse();
        }

        return sortedItems;
    },
    downloadFileAsPdf(responseData, fileName) {
        this.downloadFile(responseData, "{0}.pdf".format(fileName), 'application/pdf;charset=utf-8');
    },
    downloadFileAsCsv(responseData, fileName) {
        this.downloadFile(responseData, "{0}.csv".format(fileName), 'text/csv;charset=utf-8');
    },
    downloadFile(responseData, fileName, typeString) {
        var blob = new Blob([responseData], { type: typeString });

        //For IE,
        if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(blob, fileName);
        } else {
            var a = document.createElement('a');
            if (a.download !== undefined) {
                var url = URL.createObjectURL(blob);
                a.setAttribute("href", url);
                a.setAttribute("download", fileName);
                a.style.visibility = "hidden";

                //For firefox, we need append the a tag on the page to make 
                //FF open the download dialog.
                //(Chrome works without append the a tag on the page)
                document.body.appendChild(a);

                a.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } else {
                const reader = new FileReader();
                reader.onload = function () {
                    window.location.href = reader.result;
                };
                reader.readAsDataURL(blob);
            }

            setTimeout(function () {
                document.body.removeChild(a);
            }, 3000);
        }
    },
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    },
    stringify(val) {
        if (typeof val === 'number' || typeof val === 'string') {
            return String(val);
        }

        return "";
    },
    getHoursOfWork(hoursOfWork, hoursOfWorkFrequencyLookup) {
        if (!hoursOfWorkFrequencyLookup || !(hoursOfWorkFrequencyLookup.Name || hoursOfWorkFrequencyLookup.label) || !hoursOfWork) {
            return constants.NOT_PROVIDED;
        }
        const frequency = hoursOfWorkFrequencyLookup.Name ? hoursOfWorkFrequencyLookup.Name : hoursOfWorkFrequencyLookup.label;

        const hours = hoursOfWork + ' hours per ';

        switch (frequency.toLowerCase()) {
            case 'weekly':
                return hours + 'week';

            case 'monthly':
                return hours + 'month';

            case 'fortnightly':
                return hours + 'fortnight';

            case '2 weekly':
                return hours + ' 2 weeks';

            case '4 weekly':
                return hours + '4 weeks';

            case 'bi-monthly':
                return hours + 'Bi-month';
        }
    },
    pickDeep(obj, propertyPath) {
        if (!propertyPath)
            return null;

        var properties = propertyPath.split('.');
        
        for (var i = 0; i < properties.length; i++) {
            if (!obj || !obj.hasOwnProperty(properties[i])) {
                return null;
            }
    
            obj = obj[properties[i]];
        }
    
        return obj;        
    },
    formatShortTime(timeString, roundToMinutes = null, display24HrFormat = false) {
        let hours = null;
        let minutes = 0;
        let ampm = null;

        const padSingleDigit = (digit) => {
            if (digit === 0) {
                digit = "00";
            } else if (digit < 10) {
                digit = '0{0}'.format(digit);
            }
            return digit;
        };

        const getAMPM = (char) => {
            if (char === "a") {
                return constants.times.AM;
            } else if (char === "p") {
                return constants.times.PM;
            }
            return null;
        };

        const validateSingleHour = (hour) => {
            hours = parseInt(hour, 10);
            if (hours === 0) {
                hours = 12;
            }

            if (!ampm) {
                ampm = constants.times.AM;
            }
        };

        const validateDoubleHour = (hour) => {
            if (isNaN(Number(hour))) {
                return null;
            } else {
                let _hours = parseInt(hour, 10);
                if (ampm) {
                    if (ampm === constants.times.AM) {
                        if (_hours < 0 || _hours > 12) {
                            return null;
                        }
                    } else if (ampm === constants.times.PM) {
                        if (_hours < 1 || _hours > 12) {
                            return null;
                        }
                    }
                } else {
                    if (_hours < 0 || _hours > 24) {
                        return null;
                    } else {
                        if (_hours >= 0 && _hours < 12) {
                            ampm = constants.times.AM;;
                        } else if (_hours >= 12 && hours <= 24) {
                            if (_hours === 24) {
                                ampm = constants.times.AM;;
                            } else {
                                ampm = constants.times.PM;;
                            }
                            if (_hours !== 12) {
                                _hours = _hours - 12;
                            }
                        }
                    }
                }
                hours = _hours;
            }
        };

        const validateMinutes = (_minutes) => {
            if (isNaN(Number(_minutes))) {
                minutes = null;
            } else {
                _minutes = parseInt(_minutes, 10);
                if (_minutes < 0 || _minutes > 59) {
                    minutes = null;
                } else {
                    if (roundToMinutes) {
                        roundToMinutes = parseInt(roundToMinutes, 10);
                        _minutes = Math.round(_minutes / roundToMinutes) * roundToMinutes;
                        if (_minutes === 60) {
                            _minutes = 0;
                            hours += 1;
                            if (hours === 12) {
                                if (ampm === constants.times.AM) {
                                    ampm = constants.times.PM;
                                } else if (ampm === constants.times.PM) {
                                    ampm = constants.times.AM;
                                }
                            } else if (hours > 12) {
                                hours = hours - 12;
                            }
                        }
                    }
                    minutes = _minutes;
                }
            }
        };

        // convert to lowercase and remove all non-alphanumeric characters
        timeString = timeString.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

        const regEx = new RegExp(/^(\d{0,4})(a|p)?(m)?$/g);
        if (regEx.test(timeString)) {

            // validate id there is a am or pm modifer
            const lastChar = timeString[timeString.length - 1];
            if (isNaN(Number(lastChar))) {
                timeString = timeString.slice(0, -1);
                const secondLastChar = timeString[timeString.length - 1];
                const secondLastCharIsNotANumber = isNaN(Number(secondLastChar));
                if (lastChar === "a" || lastChar === "p") {
                    ampm = getAMPM(lastChar);
                    if (secondLastCharIsNotANumber) {
                        return null;
                    }
                } else if (lastChar === "m") {
                    if (secondLastCharIsNotANumber) {
                        timeString = timeString.slice(0, -1);
                        if (secondLastChar === "a" || secondLastChar === "p") {
                            ampm = getAMPM(secondLastChar);
                        } else {
                            return null;
                        }
                    }
                } else {
                    return null;
                }
            }

            const stringLength = timeString.length;

            switch (stringLength) {
                case 1:
                    validateSingleHour(timeString);
                    break;
                case 2:
                    validateDoubleHour(timeString);
                    break;
                case 3:
                    validateSingleHour(timeString[0]);
                    validateMinutes(timeString.slice(1, 3));
                    break;
                case 4:
                    validateDoubleHour(timeString.slice(0, 2));
                    validateMinutes(timeString.slice(2, 4));
                    break;
            }

            if (hours === null || minutes === null) {
                return null;
            }

            if (display24HrFormat) {
                if (ampm === constants.times.PM) {
                    if (hours !== 12) {
                        hours = hours + 12;
            }
                } else {
                    if (hours === 12) {
                        hours = 0;
                    }
                }
                return '{0}:{1}'.format(padSingleDigit(hours), padSingleDigit(minutes));
            } else {
                return '{0}:{1} {2}'.format(hours, padSingleDigit(minutes), ampm);
            }
        } else {
            return null;
        }
    },
    convertDecimalTime(time) {
        const hours = Math.trunc(time);
        const minutes = Math.round(60 * (time - hours));
        return [hours, minutes];
    },
    getLeaveSessionLabel(leaveSession) {
        var leaveSessionLabel = "";
        if (leaveSession === constants.ENUMS.LEAVE_HALFDAY_SESSIONS.FIRST_HALF.VALUE) 
            leaveSessionLabel = constants.ENUMS.LEAVE_HALFDAY_SESSIONS.FIRST_HALF.LABEL;
        else if (leaveSession === constants.ENUMS.LEAVE_HALFDAY_SESSIONS.SECOND_HALF.VALUE)
            leaveSessionLabel = constants.ENUMS.LEAVE_HALFDAY_SESSIONS.SECOND_HALF.LABEL;
        else
            leaveSessionLabel = "";

        return leaveSessionLabel;
    },
    getLeaveDateRangeFormat(leaveApp, leaveConfigOption) {
        var dateRangeFormat = null;
        var startDate = moment.utc(leaveApp.StartDate);
        var endDate = moment.utc(leaveApp.EndDate);
        var startSession = leaveApp.StartSession;
        var endSession = leaveApp.EndSession;
        var dateFormatter = constants.dates.formats.FULL_WITH_WEEKDAY;
        var singleDay = false;
        if (startDate.isSame(endDate)) {
            singleDay = true;
        }

        let showHalfDaySessions = false;
        if (leaveConfigOption && leaveConfigOption.showHalfDaySessions) {
            showHalfDaySessions = leaveConfigOption.showHalfDaySessions;
        }

        if (showHalfDaySessions) {         
            dateRangeFormat = (
                <span>
                    {utils.formatDate(startDate, false, dateFormatter)}
                    <span>
                        {((singleDay && (leaveApp.WorkingDays === 0.5 || leaveApp.WorkingDays === -0.5) && startSession !== null) || (!singleDay && startSession === constants.ENUMS.LEAVE_HALFDAY_SESSIONS.SECOND_HALF.VALUE)) ? <span>&nbsp;({utils.getLeaveSessionLabel(startSession)})</span> : null}                        
                    </span>
                    <span className="la-dates-end-date">
                        {((((singleDay && (leaveApp.WorkingDays === 1 || leaveApp.WorkingDays === -1)) || !singleDay) && endSession !== null) || endSession === null) ? (<span><span className="dash">&nbsp;–&nbsp;</span>{utils.formatDate(endDate, false, dateFormatter)}</span>) : null }                        
                    </span>
                    <span>
                        {(!singleDay && endSession === constants.ENUMS.LEAVE_HALFDAY_SESSIONS.FIRST_HALF.VALUE) ? <span>({utils.getLeaveSessionLabel(endSession)})&nbsp;</span> : null}                        
                    </span>
                </span>
            );
        } else {
            dateRangeFormat = (
                <span>
                    {utils.formatDate(startDate, false, dateFormatter)} 
                    <span className="la-dates-end-date">{!singleDay ? (<span><span className="dash">&nbsp;–&nbsp;</span>{utils.formatDate(endDate, false, dateFormatter)}</span>) : null} </span>
                </span>
            );
        };

        return dateRangeFormat;
    },

    convertTimeTo24(currentValue) {
        if (currentValue !== undefined && currentValue !== null) {
            const [match, times, format] = currentValue.match(/(\d?\d:\d\d)\s*(\w{2})/i);
            var [hours, minutes] = times.split(':');

            if (match !== currentValue) {
                return null;
            }
            if (hours === '12') {
                hours = '00';
            }
            if (format === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }

            return hours + ":" + minutes;            
        }
    },

    convertHours(time) {
        // var time24 = this.convertTimeTo24(time);
        var time24 = moment(time, "h:mm A").format("H:mm");
        var arrTime = time24.split(':');
        var decTime = parseInt((arrTime[1] / 6) * 10, 10);
        
        return parseFloat(parseInt(arrTime[0], 10) + '.' + (decTime < 10 ? '0' : '') + decTime);
    },

    hourDiffStartEndTimes(startTime, endTime) {
        var endTimeHours = this.convertHours(endTime);
        var startTimeHours = this.convertHours(startTime);
        
        return endTimeHours - startTimeHours;
    },
};

export default utils;