'use strict';

import configOptionsActionCreators from 'actions/ConfigOptionsActionCreators';
import configOptionsStore from 'stores/ConfigOptionsStore';
import personStore from 'stores/PersonStore';
import utils from 'services/utils';

export default class ConfigOptionsManager {
    constructor() {
        this.loggedInUser;
        this.defaultApiKey;
    }

    setLoggedInUser(person) {
        this.loggedInUser = person;
        this.defaultApiKey = person.ApiKey;
    }
    
    getCachedOptions(apiKey) {
        apiKey = apiKey || this.getApiKey();
        return configOptionsStore.getOptions(apiKey);
    };

    getOptions(apiKey) {
        apiKey = apiKey || this.getApiKey();
        configOptionsActionCreators.getOptions(apiKey);
    }

    getOption(option, apiKey) {
        apiKey = apiKey || this.getApiKey();
        return utils.pickDeep(this.getCachedOptions(apiKey), 'options.' + option);
    }

    getApiKey() {
        if (this.defaultApiKey) {
            return this.defaultApiKey;
        } else {
            const person = personStore.getLoggedInPersonData();
            if (person) {
                this.defaultApiKey = person.ApiKey;
                return this.defaultApiKey;
            } else {
                return null;
            }
        }
    }
}
