'use strict';

import {EventEmitter} from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';
import processAction from 'services/storeUtils';
import _ from 'underscore';


var _workflowScopes = {};
var _userWorkflowScopes = {};
var _singleWorkflowScope = {};
var _newlyAddedScope = {};
var _storeStatuses = {
    error: null,
    success: false,
    loading: false,
};

var loadWorkflowScopes = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _workflowScopes = responseBody;
    });
};

var loadUserWorkflowScopes = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _userWorkflowScopes = responseBody;
    });
};

var loadSingleWorkflowScope = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _singleWorkflowScope = responseBody;
    });
};

var addWorkflowScope = function (response) {
    var actions = processAction(response, function (responseBody) {
        _newlyAddedScope = responseBody;
    });

    _newlyAddedScope.loading = actions.loading;
    _newlyAddedScope.error = actions.error;
};

var updateWorkflow = function (response) {
    _storeStatuses = processAction(response);
};

var WorkflowStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        WorkflowStore.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        WorkflowStore.removeListener(appConstants.events.CHANGE, callback);
    },
    addUpdateChangeListener(callback) {
        WorkflowStore.on(appConstants.events.UPDATE_WORKFLOW_SCOPE, callback);
    },
    removeUpdateChangeListener(callback) {
        WorkflowStore.removeListener(appConstants.events.UPDATE_WORKFLOW_SCOPE, callback);
    },
    getWorkflowScopesData() {
        var response = _storeStatuses;
        response.workflows = _workflowScopes;
        return response;
    },
    getUserWorkflowScopesData() {
        var response = _storeStatuses;
        response.userWorkflowScopes = _userWorkflowScopes;
        return response;
    },
    getSingleWorkflowScopeData() {
        var response = _storeStatuses;
        response.singleworkflowscope = _singleWorkflowScope;
        response.newlyAddedScope = _newlyAddedScope;
        return response;
    },
    getLatestAddedScope() {
        return _newlyAddedScope;
    },
});


WorkflowStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {
        case appConstants.actions.WORKFLOWS.GET_WORKFLOW_SCOPES:
            loadWorkflowScopes(action);
            WorkflowStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.WORKFLOWS.GET_USER_WORKFLOW_SCOPES:
            loadUserWorkflowScopes(action);
            WorkflowStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.WORKFLOWS.GET_SINGLE_WORKFLOW_SCOPE:
            loadSingleWorkflowScope(action);
            WorkflowStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.WORKFLOWS.ADD_WORKFLOW:
            addWorkflowScope(action);
            WorkflowStore.emit(appConstants.events.UPDATE_WORKFLOW_SCOPE);
            break;

        case appConstants.actions.WORKFLOWS.UPDATE_WORKFLOW:
            updateWorkflow(action);
            WorkflowStore.emit(appConstants.events.CHANGE);
            break;            

        default:
            return true;
    }

    return true;
});

export default WorkflowStore; 