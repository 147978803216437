'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import ModalHeader from 'components/base/ModalHeader';
import ModalBody from 'components/base/ModalBody';
import ModalFooter from 'components/base/ModalFooter';
import modalActions from 'actions/ModalActionCreators';


const propTypes = {
    title: PropTypes.string.isRequired,
    onConfirmCallback: PropTypes.func,
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonText: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    includeCancelButton: PropTypes.bool,
    openSecondModal: PropTypes.bool,
};

const defaultProps = {
    buttonText: "Yes, delete",
    closeAfterConfirm: true,
    cancelButtonText: "Cancel",
    includeCancelButton: true,
    openSecondModal: false,
};

class ConfirmModalContent extends React.Component {

    constructor(props) {
        super(props);
        this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
        this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    }

    handleConfirmButtonClick() {         
        if (this.props.openSecondModal) {
            modalActions.hideModal();
        }     
        this.props.onConfirmCallback(this.props.returnValue);
        if (this.props.closeAfterConfirm) {
            modalActions.hideModal();
        }
    }

    handleCancelButtonClick() {
        modalActions.hideModal();
    }

    render() {
        return (
            <div>
                <ModalHeader showCloseButton={false}>{this.props.title}</ModalHeader>

                <ModalBody>
                    {this.props.message}
                </ModalBody>

                <ModalFooter>
                    <div className="modal-footer">
                        {(this.props.includeCancelButton) ?
                            (
                                <button
                                    className="button"
                                    onClick={this.handleCancelButtonClick}
                            
                                >
                                    {this.props.cancelButtonText}
                                </button>
                            ) : null}
                        <button
                            className="button--primary"
                            type="submit"
                            onClick={this.handleConfirmButtonClick}
                        >
                            {this.props.buttonText}
                        </button>
                    </div>
                </ModalFooter>
            </div>
        );
    }
}

ConfirmModalContent.propTypes = propTypes;
ConfirmModalContent.defaultProps = defaultProps;
export default ConfirmModalContent; 

