'use strict';

import 'core-js';

import React from 'react';
import { render } from 'react-dom';
import { Router, hashHistory } from 'react-router';

import routes from '../config/routes';

import AnalyticsManager from 'services/analyticsManager';
import appConstants from 'constants/appConstants';

AnalyticsManager.initialize(appConstants.isAnalyticsDebugEnabled);

function logPageView() {
    AnalyticsManager.pageView(window.location.hash);
}

render((
    <Router
        onUpdate={logPageView}
        history={hashHistory}
    >
        {routes}
    </Router>
), document.getElementById('content'));