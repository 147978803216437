'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import utils from 'services/utils';
import _ from 'underscore';

import 'styles/ColumnRow.scss';

const propTypes = {
    id: PropTypes.string,
    equalHeights: PropTypes.bool,
};

class ColumnRow extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.adjustHeights();
    }

    componentDidUpdate() {
        this.adjustHeights();
    }

    adjustHeights() {
        if (this.props.equalHeights) {
            const originalPosition = window.pageYOffset;
            var containerEl = this.refs.row;

            var elements = containerEl.querySelectorAll('.gc-grid-cell');

            if (elements.length > 0) {
                // reset heights
                _.each(elements, function (el) {
                    el.style.height = "initial";
                });                            

                var columnCount = Math.floor(containerEl.clientWidth / elements[0].clientWidth);

                if (columnCount > 1) {

                    var elementArray = Array.prototype.slice.call(elements, 0);  //convert the NodeList from querySelector into an Array
                    var groupedArray = utils.createGroupedArray(elementArray, columnCount);

                    _.each(groupedArray, function (arr) {
                        var maxHeight = _.max(arr, function (el) {
                            return el.offsetHeight;
                        });

                        _.each(arr, function (el) {
                            el.style.height = ((maxHeight.offsetHeight) + "px");
                        });                            
                    });

                    window.scrollTo(window.pageXOffset, originalPosition);
                }           
            }            
        }
    }

    render() {
        return (
            <div id={this.props.id || null} className={"grid-container clear " + this.props.className} ref="row">
                {this.props.children}
            </div>
        );
    }
}

ColumnRow.propTypes = propTypes;

export default ColumnRow; 
