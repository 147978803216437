'use strict';

import { EventEmitter } from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';
import utils from 'services/utils';

import processAction from 'services/storeUtils';
import _ from 'underscore';

var _people = null;
var _nextPageLink = null;
var _personCount = 0;
var _filteredPeople = [];
var _currentPerson = null;
var _loggedInPerson = null;
var _personIdentity = null;
var _resetPasswordResponse = null;
var _closeCurrentPersonIdentityResponse = null;
var _updateUsernameResponse = null;
var _storeStatuses = {
    error: null,
    success: false,
    loading: false,
};
var _personEmployment = null;
var _personEmployer = null;
var _personSalary = null;
var _personTax = null;
var _personTfn = null;
let _personNotifications = null;
let _companyLogo = null;


/**
 * Create an Person item.
 * @param  {string} Person The name of the PERSON
 */
var addPerson = function (response) {
    _storeStatuses = processAction(response.data);
};


/**
 * Delete an PERSON item.
 * @param  {string} id The id of the PERSON
 */
var removePerson = function (response) {
    _storeStatuses = processAction(response.data);
};


/**
 * Updates an PERSON item.
 * @param  {object} PERSON An object literal containing only the data to be updated
 */


var editPerson = function (response) {
    _storeStatuses = processAction(response);
};


var loadPeople = function (response) {
    _storeStatuses = 
    (response, function (responseBody) {
        _people = responseBody.Items;
        _nextPageLink = responseBody.NextPageLink;
        _personCount = responseBody.Count;
    });
};

var loadMorePeople = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        _people = _people.concat(responseBody.Items);
        _nextPageLink = responseBody.NextPageLink;
    });
};

var loadMoreFilteredPeople = function (response) {
    var actions =  _storeStatuses = processAction(response, function (responseBody) {
        _filteredPeople.people.Items = _filteredPeople.people.Items.concat(responseBody.Items);
        _filteredPeople.people.NextPageLink = responseBody.NextPageLink;
    });

    _filteredPeople.loadingNextPeople = actions.loading;
};

var appendPeople = function (response) {
    _storeStatuses = processAction(response, function (responseBody) {
        if (_people === null) {
            _people = responseBody;
        } else {
            Array.prototype.push.apply(_people, response.body);
        }
    });
};

var loadSinglePerson = function (response) {
    if (_currentPerson && response.queryParams && parseInt(response.queryParams.id, 10) !== _currentPerson.Id) {
        _currentPerson = null;
    }
    
    _storeStatuses = processAction(response, function (responseBody) {
        if (!_currentPerson) {
            _currentPerson = {};
        }
        _.extend(_currentPerson, responseBody);
    });

};

var singlePersonName = function (response, isUpdate) {
    if (!_currentPerson.name) {
        _currentPerson.name = {};
    }
    
    var actions = processAction(response, function (responseBody) {
        _currentPerson.name = _.extend(_currentPerson.name, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.name.loading = actions.loading;
    }

    _currentPerson.name.error = actions.error;

};

var singlePersonDemographic = function (response, isUpdate) {
    if (!_currentPerson.demographic) {
        _currentPerson.demographic = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.demographic = _.extend(_currentPerson.demographic, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.demographic.loading = actions.loading;
    }

    _currentPerson.demographic.error = actions.error;

};

var singlePersonMedicalConditions = function (response, isUpdate) {
    if (!_currentPerson.medicalConditions) {
        _currentPerson.medicalConditions = {};
    }
    
    var actions = processAction(response, function (responseBody) {
        _currentPerson.medicalConditions = _.extend(_currentPerson.medicalConditions, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.medicalConditions.loading = actions.loading;
    }

    _currentPerson.medicalConditions.error = actions.error;

};

var singlePersonPersonalContacts = function (response, isUpdate) {
    if (!_currentPerson.personalContacts) {
        _currentPerson.personalContacts = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.personalContacts = _.extend(_currentPerson.personalContacts, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.personalContacts.loading = actions.loading;
    }
    _currentPerson.personalContacts.error = actions.error;
};

var singlePersonEmailContacts = function (response, isUpdate) {
    if (!_currentPerson.emailContacts) {
        _currentPerson.emailContacts = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.emailContacts.emails = responseBody;
    });

    if (!isUpdate) {
        _currentPerson.emailContacts.loading = actions.loading;
    }
    _currentPerson.emailContacts.error = actions.error;
};

var singlePersonWorkContacts = function (response, isUpdate) {
    if (!_currentPerson.workContacts) {
        _currentPerson.workContacts = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.workContacts = _.extend(_currentPerson.workContacts, responseBody);
        _currentPerson.defaultEmail = responseBody.Content.EmailContacts[0];
    });

    if (!isUpdate) {
        _currentPerson.workContacts.loading = actions.loading;
    }
    _currentPerson.workContacts.error = actions.error;

};

var singlePersonAddressContacts = function (response, isUpdate) {
    if (!_currentPerson.addressContacts) {
        _currentPerson.addressContacts = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.addressContacts = _.extend(_currentPerson.addressContacts, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.addressContacts.loading = actions.loading;
    }
    _currentPerson.addressContacts.error = actions.error;

};

var getSinglePersonLeave = function (response) {
    if (!_currentPerson.leaveApplications) {
        _currentPerson.leaveApplications = {};
    }

    const queryType = response.queryParams.Name;

    let currentApplications = [];
    if (_currentPerson.leaveApplications[queryType] && _currentPerson.leaveApplications[queryType].applications && response.queryParams.PageNumber !== 1) {
        currentApplications = _currentPerson.leaveApplications[queryType].applications;
    } else {
        _currentPerson.leaveApplications[queryType] = {};
    }

    var actions = processAction(response, function (responseBody) {
        const applicationInfo = {
            applications: currentApplications.concat(responseBody.Items),
            pagingInfo: {
                totalCount: responseBody.TotalCount,
                pageSize: responseBody.PageSize,
                page: responseBody.Page,   
            },
        };

        _currentPerson.leaveApplications[queryType] = applicationInfo;
    });

    _currentPerson.leaveApplications[queryType].loading = actions.loading;
    _currentPerson.leaveApplications[queryType].error = actions.error; 
    _currentPerson.leaveApplications[queryType].model = response.queryParams;
    _currentPerson.leaveApplications.loading = actions.loading;
    _currentPerson.leaveApplications.error = actions.error;
};

var updatePersonLeaveApplicationTraces = function (response) {
    if (response.data.body) {   
        var leaveApplicationTrace = response.data.body;

        if (!_currentPerson.leaveApplications.leaveApplicationTraces) {
            _currentPerson.leaveApplications.leaveApplicationTraces = [];
        }

        var leaveApplicationTraceIndex = _.indexOf(_currentPerson.leaveApplications.leaveApplicationTraces, leaveApplicationTrace);

        if (leaveApplicationTraceIndex !== -1) {
            _currentPerson.leaveApplications.leaveApplicationTraces[leaveApplicationTraceIndex] = leaveApplicationTrace;
        } else {
            _currentPerson.leaveApplications.leaveApplicationTraces.push(leaveApplicationTrace);
        }
    }
};

var updateSinglePersonLeave = function (response) {
    if (!_currentPerson.leaveApplication) {
        _currentPerson.leaveApplication = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.leaveApplication = responseBody;
    });

    _currentPerson.leaveApplication.loading = actions.loading;
    _currentPerson.leaveApplication.error = actions.error; 
};

var singlePersonLeaveReasons = function (response) {
    if (!_currentPerson.leaveReasons) {
        _currentPerson.leaveReasons = [];
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.leaveReasons = responseBody;
    });

    _currentPerson.leaveReasons.loading = actions.loading;
    _currentPerson.leaveReasons.error = actions.error; 

};

var singlePersonLeaveBalances = function (response) {

    if (!_currentPerson.leaveBalances) {
        _currentPerson.leaveBalances = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.leaveBalances.balances = responseBody;
    });

    _currentPerson.leaveBalances.loading = actions.loading;
    _currentPerson.leaveBalances.error = actions.error; 

};

var singlePersonBanks = function (response) {
    if (!_currentPerson.PersonBankDetails) {
        _currentPerson.PersonBankDetails = {};
    }

    const actions = processAction(response, function (responseBody) {
        _currentPerson.PersonBankDetails = responseBody;
    });

    _currentPerson.PersonBankDetails.loading = actions.loading;
    _currentPerson.PersonBankDetails.error = actions.error;
};

var singlePersonEmergencyContacts = function (response, isUpdate) {
    if (!_currentPerson.personEmergencyContacts) {
        _currentPerson.personEmergencyContacts = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.personEmergencyContacts = _.extend(_currentPerson.personEmergencyContacts, responseBody);
    });

    if (!isUpdate) {
        _currentPerson.personEmergencyContacts.loading = actions.loading;
    }
    _currentPerson.personEmergencyContacts.error = actions.error;
};

var singlePersonPayAdvices = function (response) {
    if (!_currentPerson.payAdvices) {
        _currentPerson.payAdvices = {};
    }

    let currentPayAdvices = [];

    if (_currentPerson.payAdvices && _currentPerson.payAdvices.items) {
        if (!response.queryParams.reset) {
            currentPayAdvices = _currentPerson.payAdvices.items;
        }
    } 

    var actions = processAction(response, function (responseBody) {
        const payAdvicesInfo = {
            items: responseBody.Items ? currentPayAdvices.concat(responseBody.Items) : null,
            pagingInfo: {
                totalCount: responseBody.TotalCount,
                pageSize: responseBody.PageSize,
                page: responseBody.Page,   
            },
        };

        _currentPerson.payAdvices = payAdvicesInfo;
    });

    _currentPerson.payAdvices.loading = actions.loading;
    _currentPerson.payAdvices.error = actions.error;
    _currentPerson.payAdvices.model = response.queryParams;

};

var singlePersonPaySummaries = function (response) {
    if (!_currentPerson.paySummaries) {
        _currentPerson.paySummaries = {};
    }

    let currentPaySummaries = [];

    if (_currentPerson.paySummaries && _currentPerson.paySummaries.items && response.queryParams.pageNum !== 1) {
        currentPaySummaries = _currentPerson.paySummaries.items;
    } 

    var actions = processAction(response, function (responseBody) {
        const paySummariesInfo = {
            items: currentPaySummaries.concat(responseBody.Items),
            pagingInfo: {
                totalCount: responseBody.TotalCount,
                pageSize: responseBody.PageSize,
                page: responseBody.Page,   
            },
        };

        _currentPerson.paySummaries = paySummariesInfo;
    });

    _currentPerson.paySummaries.loading = actions.loading;
    _currentPerson.paySummaries.error = actions.error;
    _currentPerson.paySummaries.model = response.queryParams;
};


var singlePersonPayAdvicePdf = function (response) {
    if (!_currentPerson.PayAdvicePdf) {
        _currentPerson.PayAdvicePdf = {};
    }
    var actions = processAction(response, function (responseBody) {
        if (responseBody.status) {
            if (responseBody.status === 200) {
                var year = new Date(response.queryParams.periodEndDate).getFullYear();
                var month = new Date(response.queryParams.periodEndDate).getMonth() + 1;
                var day = new Date(response.queryParams.periodEndDate).getDate();

                utils.downloadFileAsPdf(responseBody.response, "Pay_Advice_PeriodEnd_{0}-{1}-{2}".format(year, month, day));
                _currentPerson.PayAdvicePdf.error = null;
            } else {
                _currentPerson.PayAdvicePdf.error = responseBody.statusText;
            }
        }
    });

    _currentPerson.payAdvices.loading = actions.loading;
    _currentPerson.PayAdvicePdf.loading = actions.loading;
};

var singlePersonPaySummaryPdf = function (response) {
    if (!_currentPerson.PaySummaryPdf) {
        _currentPerson.PaySummaryPdf = {};
    }
    var actions = processAction(response, function (responseBody) {
        if (responseBody.status) {
            if (responseBody.status === 200) {
                
                const fileName = response.queryParams.type && response.queryParams.type !== "" 
                    ? "Pay_Summary_FY{0}_{1}".format(response.queryParams.financialYear, response.queryParams.type)
                    : "Pay_Summary_FY{0}".format(response.queryParams.financialYear);

                utils.downloadFileAsPdf(responseBody.response, fileName);
                _currentPerson.PaySummaryPdf.error = null;
            } else {
                _currentPerson.PaySummaryPdf.error = responseBody.statusText;
            }
        }
    });
    _currentPerson.paySummaries.loading = actions.loading;
    _currentPerson.PaySummaryPdf.loading = actions.loading;
};





var getLoggedInPerson = function (response) {
    _loggedInPerson = null;
    _storeStatuses = processAction(response, function (responseBody) {
        _loggedInPerson = responseBody;
    });
};

var getPersonIdentity = function (response) {
    if (!_personIdentity) {
        _personIdentity = {};
    }
    _personIdentity.activationInfo = {};
    var actions = processAction(response, function (responseBody) {
        _personIdentity = responseBody ? responseBody : {};
    });

    _personIdentity.loading = actions.loading;
};

var getPersonActivationInfo = function (response) {
    if (!_personIdentity) {
        _personIdentity = {};
    }

    if (!_personIdentity.activationInfo) {
        _personIdentity.activationInfo = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personIdentity.activationInfo = responseBody ? responseBody : {};
    });

    _personIdentity.activationInfo.error = actions.error;
    _personIdentity.loading = actions.loading;
};

var updatePersonAccountInfo = (response) => {
    var actions = processAction(response);

    _personIdentity.loading = actions.loading;
};

var getResetIdentityPasswordInfo = function (response) {
    if (!_resetPasswordResponse) {
        _resetPasswordResponse = {};
    }

    var actions = processAction(response);

    _resetPasswordResponse.error = actions.error;
    _resetPasswordResponse.loading = actions.loading;
};

var getCloseCurrentPersonIdentityInfo = function (response) {
    if (!_closeCurrentPersonIdentityResponse) {
        _closeCurrentPersonIdentityResponse = {};
    }

    var actions = processAction(response, function (responseBody) {
        if (responseBody && responseBody === true) {
            _closeCurrentPersonIdentityResponse.success = true;
        }
    });

    _closeCurrentPersonIdentityResponse.error = actions.error;
    _closeCurrentPersonIdentityResponse.loading = actions.loading;
};

var getUpdateUsernameInfo = function (response) {
    if (!_updateUsernameResponse) {
        _updateUsernameResponse = {};
    }

    var actions = processAction(response, function (responseBody) {
        if (responseBody && responseBody === true) {
            _updateUsernameResponse.success = true;
        }
    });

    _updateUsernameResponse.error = actions.error;
    _updateUsernameResponse.loading = actions.loading;
};

var singlePersonWorkflowDetails = function (response) {
    if (!_currentPerson.workflowDetails) {
        _currentPerson.workflowDetails = {};
    }

    var workflowType = response.queryParams.workflowType;
    
    if (!_currentPerson.workflowDetails[workflowType]) {
        _currentPerson.workflowDetails[workflowType] = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.workflowDetails[responseBody.WorkflowType].workflowDetails = responseBody;
    });

    _currentPerson.workflowDetails[workflowType].loading = actions.loading;
    _currentPerson.workflowDetails[workflowType].error = actions.error;         

};

var singlePersonEmployment = function (response) {
    if (!_personEmployment) {
        _personEmployment = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personEmployment = responseBody;
    });

    _personEmployment.loading = actions.loading;
    _personEmployment.error = actions.error;
};

var singlePersonEmployer = function (response) {
    if (!_personEmployer) {
        _personEmployer = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personEmployer = responseBody;
    });

    _personEmployer.loading = actions.loading;
    _personEmployer.error = actions.error;
};

var singlePersonSalary = function (response) {
    if (!_personSalary) {
        _personSalary = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personSalary = responseBody;
    });

    _personSalary.loading = actions.loading;
    _personSalary.error = actions.error;
};

var singlePersonTax = function (response) {
    if (!_personTax) {
        _personTax = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personTax = responseBody;
    });

    _personTax.loading = actions.loading;
    _personTax.error = actions.error;
};

var singlePersonTfn = function (response) {
    if (!_personTfn) {
        _personTfn = {};
    }

    var actions = processAction(response, function (responseBody) {
        _personTfn = responseBody;
    });

    _personTfn.loading = actions.loading;
    _personTfn.error = actions.error;
};

var singlePersonRolesTeams = function (response) {
    if (!_currentPerson) {
        _currentPerson = {
            teams: {},
        };
    } else if (!_currentPerson.teams) {
        _currentPerson.teams = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.teams = responseBody;
    });

    _currentPerson.teams.loading = actions.loading;
    _currentPerson.teams.error = actions.error; 
};

var singlePersonAssociatedTeams = function (response) {
    if (!_currentPerson.associatedTeams) {
        _currentPerson.associatedTeams = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.associatedTeams.teams = responseBody;
    });

    _currentPerson.associatedTeams.loading = actions.loading;
    _currentPerson.associatedTeams.error = actions.error;     
};

var singlePersonSuperannuation = function (response) {
    if (!_currentPerson.Superannuation) {
        _currentPerson.Superannuation = {};
    }

    var actions = processAction(response, function (responseBody) {
        _currentPerson.Superannuation = responseBody;
    });

    _currentPerson.Superannuation.loading = actions.loading;
    _currentPerson.Superannuation.error = actions.error;
};

var singlePersonWorkDaysAndHours = function (response) {
    if (!_currentPerson.workDaysAndHours) {
        _currentPerson.workDaysAndHours = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.workDaysAndHours.DaysHours = responseBody;
    });

    _currentPerson.workDaysAndHours.loading = actions.loading;
    _currentPerson.workDaysAndHours.error = actions.error;
    _currentPerson.workDaysAndHours.searchParams = response.queryParams.searchParams;
};

var singlePersonAvatar = function (response) {
    if (!_currentPerson.PersonAvatar) {
        _currentPerson.PersonAvatar = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.PersonAvatar = responseBody;
    });

    _currentPerson.PersonAvatar.loading = actions.loading;
    _currentPerson.PersonAvatar.error = actions.error;
};

var getSinglePersonLeaveEntitlements = function (response) {
    if (!_currentPerson.leaveEntitlements) {
        _currentPerson.leaveEntitlements = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.leaveEntitlements.leaveEntitlements = responseBody;
    });

    _currentPerson.leaveEntitlements.loading = actions.loading;
    _currentPerson.leaveEntitlements.error = actions.error; 
};

var getSinglePersonLeaveBalanceValidation = function (response) {
    if (!_currentPerson.leaveBalanceValidation) {
        _currentPerson.leaveBalanceValidation = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.leaveBalanceValidation.leaveEntitlements = responseBody;
    });

    _currentPerson.leaveBalanceValidation.loading = actions.loading;
    _currentPerson.leaveBalanceValidation.error = actions.error; 
};

var getSinglePersonDelegateApprover = function (response) {
    if (!_currentPerson.delegateApprover) {
        _currentPerson.delegateApprover = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.delegateApprover.approver = responseBody;
    });

    _currentPerson.delegateApprover.loading = actions.loading;
    _currentPerson.delegateApprover.error = actions.error; 
};

var singlePersonDelegateApprover = function (response) {
    if (!_currentPerson.delegateApprover) {
        _currentPerson.delegateApprover = {};
    }
    var actions = processAction(response);

    _currentPerson.delegateApprover.loading = actions.loading;
    _currentPerson.delegateApprover.error = actions.error; 
};

var singlePersonDelegatedTo = function (response) {
    if (!_currentPerson.delegatedToMe) {
        _currentPerson.delegatedToMe = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.delegatedToMe.delegations = responseBody;
    });

    _currentPerson.delegatedToMe.loading = actions.loading;
    _currentPerson.delegatedToMe.error = actions.error;     
};

var singlePersonApproverTypes = function (response) {
    if (!_currentPerson.approverTypes) {
        _currentPerson.approverTypes = {};
    }
    var actions = processAction(response, function (responseBody) {
        _currentPerson.approverTypes = responseBody;
    });

    _currentPerson.approverTypes.loading = actions.loading;
    _currentPerson.approverTypes.error = actions.error;     
};

var singlePersonTimesheets = (response) => {
    if (!_currentPerson.PersonTimesheetDetails) {
        _currentPerson.PersonTimesheetDetails = {};
    }

    const actions = processAction(response, function (responseBody) {
        if (responseBody !== true)
            _currentPerson.PersonTimesheetDetails = responseBody;                
    });    
    _currentPerson.PersonTimesheetDetails.loading = actions.loading;
    _currentPerson.PersonTimesheetDetails.error = actions.error;
};

/**
 * Get a filtered list of people.
 */
var loadFilteredPeople = function (response) {
    if (!_filteredPeople) {
        _filteredPeople = {};
    }

    if (!_filteredPeople.people) {
        _filteredPeople.people = {};
    }

    var actions = processAction(response, function (responseBody) {
        _filteredPeople.people = responseBody ? responseBody : {};
    });

    _filteredPeople.error = actions.error;
    _filteredPeople.loading = actions.loading;
    _filteredPeople.searchTerm = response.queryParams.searchTerm;
};

const getCompanyLogo = function (response) {
    if (!_companyLogo) {
        _companyLogo = {};
    }

    var actions = processAction(response, function (responseBody) {
        _companyLogo.logo = responseBody;
    });
    _companyLogo.loading = actions.loading;
    _companyLogo.error = actions.error;
};

const getNotifications = (response) => {
    if (!_personNotifications) {
        _personNotifications = {};
    }
    var actions = processAction(response, function (responseBody) {
        _personNotifications.notifications = responseBody;
    });
    _personNotifications.loading = actions.loading;
    _personNotifications.error = actions.error;     
};

var activateIdentity = function (response) {
    if (!_personIdentity) {
        _personIdentity = {};
    }
    if (!_personIdentity.activationInfo) {
        _personIdentity.activationInfo = {};
    }
    const actions = processAction(response, null);
    _personIdentity.activationInfo.error = actions.error;
    _personIdentity.loading = actions.loading;
};

var uploadCompanyLogo = function (response) {
    if (!_companyLogo) {
        _companyLogo = {};
    }

    var actions = processAction(response);

    _companyLogo.loading = actions.loading;
    _companyLogo.error = actions.error;
};

var PersonStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        PersonStore.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        PersonStore.removeListener(appConstants.events.CHANGE, callback);
    },

    addChangeLoginPersonListener(callback) {
        this.on(appConstants.events.CHANGE_LOGIN_PERSON, callback);
    },
    removeChangeLoginPersonListener(callback) {
        this.removeListener(appConstants.events.CHANGE_LOGIN_PERSON, callback);
    },

    addUpdateLeaveAppChangeListener(callback) {
        this.on(appConstants.events.UPDATE_LEAVE_APPS, callback);
    },
    removeUpdateLeaveAppChangeListener(callback) {
        this.removeListener(appConstants.events.UPDATE_LEAVE_APPS, callback);
    },

    addUpdateLeaveAppTracesListener(callback) {
        this.on(appConstants.events.UPDATE_LEAVE_APPS_TRACES, callback);
    },
    removeUpdateLeaveAppTracesListener(callback) {
        this.removeListener(appConstants.events.UPDATE_LEAVE_APPS_TRACES, callback);
    },

    addUpdateBanksChangeListener(callback) {
        this.on(appConstants.events.UPDATE_BANKS, callback);
    },
    removeUpdateBanksChangeListener(callback) {
        this.removeListener(appConstants.events.UPDATE_BANKS, callback);
    },

    addUpdateEmergencyContactChangeListener(callback) {
        this.on(appConstants.events.UPDATE_EMERGENCY_CONTACTS, callback);
    },
    removeUpdateEmergencyContactChangeListener(callback) {
        this.removeListener(appConstants.events.UPDATE_EMERGENCY_CONTACTS, callback);
    },

    addChangePersonPaySummariesListener(callback) {
        this.on(appConstants.events.UPDATE_PERSON_PAY_SUMMARIES, callback);
    },
    removeChangePersonPaySummariesListener(callback) {
        this.removeListener(appConstants.events.UPDATE_PERSON_PAY_SUMMARIES, callback);
    },
    addChangePersonIdentityListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_IDENTITY, callback);
    },
    removeChangePersonIdentityListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_IDENTITY, callback);
    },    
    addUpdateUsernameListener(callback) {
        this.on(appConstants.events.UPDATE_USERNAME, callback);
    },
    removeUpdateUsernameListener(callback) {
        this.removeListener(appConstants.events.UPDATE_USERNAME, callback);
    },
    addDownloadPersonPayAdvicePdfListener(callback) {
        this.on(appConstants.events.DOWNLOAD_PERSON_PAY_ADVICE_PDF, callback);
    },
    removeDownloadPersonPayAdvicePdfListener(callback) {
        this.removeListener(appConstants.events.DOWNLOAD_PERSON_PAY_ADVICE_PDF, callback);
    },

    addDownloadPersonPaySummaryPdfListener(callback) {
        this.on(appConstants.events.DOWNLOAD_PERSON_PAY_SUMMARY_PDF, callback);
    },
    removeDownloadPersonPaySummaryPdfListener(callback) {
        this.removeListener(appConstants.events.DOWNLOAD_PERSON_PAY_SUMMARY_PDF, callback);
    },

    addResetPasswordListener(callback) {
        this.on(appConstants.events.RESET_IDENTITY_PASSWORD, callback);
    },
    removeResetPasswordListener(callback) {
        this.removeListener(appConstants.events.RESET_IDENTITY_PASSWORD, callback);
    },

    addCloseCurrentPersonIdentityListener(callback) {
        this.on(appConstants.events.CLOSE_CURRENT_PERSON_IDENTITY, callback);
    },
    removeCloseCurrentPersonIdentityListener(callback) {
        this.removeListener(appConstants.events.CLOSE_CURRENT_PERSON_IDENTITY, callback);
    },

    addSinglePersonEmploymentChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_EMPLOYMENT, callback);
    },

    removeSinglePersonEmploymentChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_EMPLOYMENT, callback);
    },
    
    addSinglePersonEmployerChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_EMPLOYER, callback);
    },

    removeSinglePersonEmployerChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_EMPLOYER, callback);
    },

    addSinglePersonSalaryChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_SALARY, callback);
    },

    removeSinglePersonSalaryChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_SALARY, callback);
    },

    addSinglePersonTaxChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_TAX, callback);
    },

    removeSinglePersonTaxChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_TAX, callback);
    },

    addSinglePersonTfnChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_TFN, callback);
    },

    removeSinglePersonTfnChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_TFN, callback);
    },

    addSinglePersonWorkDaysAndHoursChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_WORK_DAYS_HOURS, callback);
    },

    removeSinglePersonWorkDaysAndHoursChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_WORK_DAYS_HOURS, callback);
    },

    addSinglePersonLeaveEntitlementsChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_LEAVE_ENTITLEMENTS, callback);
    },

    removeSinglePersonLeaveEntitlementsChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_LEAVE_ENTITLEMENTS, callback);
    },

    addSinglePersonDelegateApproverChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_DELEGATE_APPROVER, callback);
    },

    removeSinglePersonDelegateApproverChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_DELEGATE_APPROVER, callback);
    },

    addSinglePersonAvatarUpdateListener(callback) {
        this.on(appConstants.events.UPDATE_PERSON_AVATAR, callback);
    },

    removeSinglePersonAvatarUpdateListener(callback) {
        this.removeListener(appConstants.events.UPDATE_PERSON_AVATAR, callback);
    },

    addSinglePersonApproverTypeChangeListener(callback) {
        this.on(appConstants.events.CHANGE_SINGLE_PERSON_APPROVER_TYPES, callback);
    },

    removeSinglePersonApproverTypeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_SINGLE_PERSON_APPROVER_TYPES, callback);
    },
    addChangeCompanyLogoListener(callback) {
        this.on(appConstants.events.CHANGE_COMPANY_LOGO, callback);
    },
    removeChangeCompanyLogoListener(callback) {
        this.removeListener(appConstants.events.CHANGE_COMPANY_LOGO, callback);
    },
    addSinglePersonNotificationsChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_NOTIFICATIONS, callback);
    },
    removeSinglePersonNotificationsChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_NOTIFICATIONS, callback);
    },
    resetWorkDaysHours() {
        _currentPerson.workDaysAndHours = {};
    },

    resetSinglePersonRolesTeams() {
        if (!_currentPerson) {
            _currentPerson = {
                teams: [],
            };
        }
        
        if (_currentPerson.hasOwnProperty('teams')) {
            _currentPerson.teams = {};        
        }
    },
    
    getPeopleData() {
        var response = _storeStatuses;
        response.people = _people;
        response.nextPageLink = _nextPageLink;
        response.personCount = _personCount;
        return response;
    },
    getPerson() {
        var response = _storeStatuses;
        response.person = _currentPerson;
        return response;
    },
    
    getFilteredPeople() {
        return _filteredPeople;
        
    },
    getLoggedInPersonData() {
        return _loggedInPerson;
    },
    getPersonIdentityData() {
        var response = _storeStatuses;
        response.personIdentity = _personIdentity;
        return response;
    },
    resetPersonIdentityData() {
        _personIdentity = null;
        var response = _storeStatuses;
        response.personIdentity = null;
        return response;
    },
    getResetIdentityPasswordData() {
        return _resetPasswordResponse;
    },
    getCloseCurrentPersonIdentityData() {
        return _closeCurrentPersonIdentityResponse;
    },
    getUpdateUsernameData() {
        return _updateUsernameResponse;
    },
    extendLoggedUserToCurrentUser() {
        _currentPerson = {};
        _.extend(_currentPerson, _loggedInPerson);
    },
    getPersonEmployment() {
        return _personEmployment;
    },
    getPersonEmployer() {
        return _personEmployer;
    },
    getPersonSalary() {
        return _personSalary;
    },
    getPersonTax() {
        return _personTax;
    },
    getPersonTfn() {
        return _personTfn;
    },
    getPersonApproverTypes() {
        return _currentPerson.approverTypes;
    },
    getCompanyLogoData() {
        return _companyLogo;
    },
    getNotifications() {
        return _personNotifications;
    },
    addUpdateTimesheetsChangeListener(callback) {
        this.on(appConstants.events.CHANGE_PERSON_TIMESHEETS, callback);
    },
    removeUpdateTimesheetsChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE_PERSON_TIMESHEETS, callback);
    },
});



PersonStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {
        case appConstants.actions.PEOPLE.LOAD_PEOPLE_FROM_SERVER:
            loadPeople(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.LOAD_MORE_PEOPLE_FROM_SERVER:
            loadMorePeople(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.LOAD_MORE_FILTERED_PEOPLE_FROM_SERVER:
            loadMoreFilteredPeople(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.LOAD_FILTERED_PEOPLE:
            loadFilteredPeople(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;            

        case appConstants.actions.PEOPLE.APPEND_PEOPLE_FROM_SERVER:
            appendPeople(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.LOAD_SINGLE_PERSON:
            loadSinglePerson(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.ADD_PERSON:
            addPerson(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.REMOVE_PERSON:
            removePerson(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_PERSON:
            editPerson(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PERSON.ACTIVATE_IDENTITY:
            activateIdentity(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_IDENTITY);
            break;

        case appConstants.actions.PERSON.GET_LOGGED_IN_PERSON:
            getLoggedInPerson(action);
            PersonStore.emit(appConstants.events.CHANGE_LOGIN_PERSON);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_NAME:
            singlePersonName(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_NAME:
            singlePersonName(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_DEMOGRAPHIC:
            singlePersonDemographic(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_DEMOGRAPHIC:
            singlePersonDemographic(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_MEDICAL_CONDITIONS:
            singlePersonMedicalConditions(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_MEDICAL_CONDITIONS:
            singlePersonMedicalConditions(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_PERSONAL_CONTACTS:
            singlePersonPersonalContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_PERSONAL_CONTACTS:
            singlePersonPersonalContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_EMAIL_CONTACTS:
            singlePersonEmailContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_PERSON_DEFAULT_EMAIL_CONTACTS:
            singlePersonEmailContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;
        
        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_ADDRESS_CONTACTS:
            singlePersonAddressContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_WORK_CONTACTS:
            singlePersonWorkContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_WORK_CONTACTS:
            singlePersonWorkContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_BANKS:
            singlePersonBanks(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_BANKS:
            singlePersonBanks(action);
            PersonStore.emit(appConstants.events.UPDATE_BANKS);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_EMERGENCY_CONTACTS:
            singlePersonEmergencyContacts(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_EMERGENCY_CONTACTS:
            singlePersonEmergencyContacts(action);
            PersonStore.emit(appConstants.events.UPDATE_EMERGENCY_CONTACTS);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE:
            getSinglePersonLeave(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE:
            updateSinglePersonLeave(action);
            PersonStore.emit(appConstants.events.UPDATE_LEAVE_APPS);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_REASONS:
            singlePersonLeaveReasons(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE_REASONS:
            singlePersonLeaveReasons(action, true);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_BALANCES:
            singlePersonLeaveBalances(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;
        
        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_PAY_ADVICES:
            singlePersonPayAdvices(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_PAY_SUMMARIES:
            singlePersonPaySummaries(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PERSON.GET_PERSON_IDENTITY:
            getPersonIdentity(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_IDENTITY);
            break;
        case appConstants.actions.PEOPLE.DOWNLOAD_PERSON_PAY_ADVICE_PDF:
            singlePersonPayAdvicePdf(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;
        
        case appConstants.actions.PEOPLE.DOWNLOAD_PERSON_PAY_SUMMARY_PDF:
            singlePersonPaySummaryPdf(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PERSON.GET_ACTIVATION_INFO:
            getPersonActivationInfo(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_IDENTITY);
            break;

        case appConstants.actions.PERSON.RESET_MFA:
            updatePersonAccountInfo(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_IDENTITY);
            break;

        case appConstants.actions.PERSON.REOPEN_PERSON_IDENTITY:
            updatePersonAccountInfo(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_IDENTITY);
            break;

        case appConstants.actions.PEOPLE.UPDATE_LEAVE_APPS_TRACES:
            updatePersonLeaveApplicationTraces(action);
            PersonStore.emit(appConstants.events.UPDATE_LEAVE_APPS_TRACES);
            break;

        case appConstants.actions.PEOPLE.REMOVE_PERSON_EMERGENCY_CONTACTS:
            singlePersonEmergencyContacts(action);
            PersonStore.emit(appConstants.events.UPDATE_EMERGENCY_CONTACTS);            
            break;

        case appConstants.actions.PERSON.SEND_RESET_IDENTITY_PASSWORD:
            getResetIdentityPasswordInfo(action);
            PersonStore.emit(appConstants.events.RESET_IDENTITY_PASSWORD);
            break;

        case appConstants.actions.PERSON.CLOSE_CURRENT_PERSON_IDENTITY:
            getCloseCurrentPersonIdentityInfo(action);
            PersonStore.emit(appConstants.events.CLOSE_CURRENT_PERSON_IDENTITY);
            break;

        case appConstants.actions.PERSON.UPDATE_USERNAME:
            getUpdateUsernameInfo(action);
            PersonStore.emit(appConstants.events.UPDATE_USERNAME);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_WORKFLOW_DETAILS:
            singlePersonWorkflowDetails(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_EMPLOYMENT:
            singlePersonEmployment(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_EMPLOYMENT);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_EMPLOYER:
            singlePersonEmployer(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_EMPLOYER);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_SALARY:
            singlePersonSalary(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_SALARY);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_TAX:
            singlePersonTax(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_TAX);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_TFN:
            singlePersonTfn(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_TFN);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_ROLES_TEAMS:
            singlePersonRolesTeams(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_ASSOCIATED_TEAMS:
            singlePersonAssociatedTeams(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_SUPERANNUATION:
            singlePersonSuperannuation(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_WORK_DAYS_HOURS:
            singlePersonWorkDaysAndHours(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_WORK_DAYS_HOURS);
            break;

        case appConstants.actions.PEOPLE.GET_AVATAR:
            singlePersonAvatar(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_AVATAR);
            break;

        case appConstants.actions.PEOPLE.UPDATE_AVATAR:
            singlePersonAvatar(action);
            PersonStore.emit(appConstants.events.UPDATE_PERSON_AVATAR);
            break;

        case appConstants.actions.PEOPLE.DELETE_AVATAR:
            singlePersonAvatar(action);
            PersonStore.emit(appConstants.events.UPDATE_PERSON_AVATAR);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_ENTITLEMENTS:
            getSinglePersonLeaveEntitlements(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_LEAVE_ENTITLEMENTS);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_BALANCE_VALIDATION:
            getSinglePersonLeaveBalanceValidation(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_LEAVE_ENTITLEMENTS);
            break;

        case appConstants.actions.PEOPLE.GET_DELEGATE_APPROVER:
            getSinglePersonDelegateApprover(action);
            PersonStore.emit(appConstants.events.CHANGE);
            break;

        case appConstants.actions.PEOPLE.EDIT_DELEGATE_APPROVER:
            singlePersonDelegateApprover(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_DELEGATE_APPROVER);
            break;

        case appConstants.actions.PEOPLE.DELETE_DELEGATE_APPROVER:
            singlePersonDelegateApprover(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_DELEGATE_APPROVER);
            break;

        case appConstants.actions.PEOPLE.GET_DELEGATED_TO_ME:
            singlePersonDelegatedTo(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_DELEGATE_APPROVER);
            break;
        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_APPROVER_TYPES:
            singlePersonApproverTypes(action);
            PersonStore.emit(appConstants.events.CHANGE_SINGLE_PERSON_APPROVER_TYPES);
            break;

        case appConstants.actions.PERSON.GET_COMPANY_LOGO:
            getCompanyLogo(action);
            PersonStore.emit(appConstants.events.CHANGE_COMPANY_LOGO);
            break;

        case appConstants.actions.PERSON.UPLOAD_COMPANY_LOGO:
            uploadCompanyLogo(action);
            PersonStore.emit(appConstants.events.CHANGE_COMPANY_LOGO);
            break;
        

        case appConstants.actions.PERSON.GET_NOTIFICATIONS:
            getNotifications(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_NOTIFICATIONS);
            break;

        case appConstants.actions.PEOPLE.GET_SINGLE_PERSON_TIMESHEETS:
            singlePersonTimesheets(action);
            PersonStore.emit(appConstants.events.CHANGE_PERSON_TIMESHEETS);
            break;

        case appConstants.actions.PEOPLE.UPDATE_SINGLE_PERSON_TIMESHEETS:
            singlePersonTimesheets(action);
            PersonStore.emit(appConstants.events.UPDATE_PERSON_TIMESHEETS);
            break;

        default:
            return true;
    }

    // Update logged in user if required
    if (_currentPerson && _loggedInPerson && _currentPerson.Id === _loggedInPerson.Id) {
        _.extend(_loggedInPerson, _currentPerson);
        PersonStore.emit(appConstants.events.CHANGE_LOGIN_PERSON);
    }

    return true;
});

export default PersonStore;
