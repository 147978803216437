'use strict';
import * as msal from "@azure/msal-browser";
import constants from 'constants/appConstants';
import authActions from 'actions/AuthActionCreators';
import { LogLevel } from '@azure/msal-browser';

export function loggerCallback(logLevel, message) {
    console.log(message);
}

const tokenRenewalOffsetSeconds = constants.auth.TOKEN_RENEWAL_OFFSET_SECONDS || 600;
let accountId = "";
let token = null;
let signedIn = false;
let wasLastTokenRetrievalSuccessful = false;
let inProgress = false;
let isNewSignIn = false;

const loggerOptions = constants.auth.MSAL_LOG_LEVEL ? {
    loggerCallback,
    logLevel: LogLevel[constants.auth.MSAL_LOG_LEVEL],
    piiLoggingEnabled: false,
} : {};

const msalConfig = {
    auth: {
        clientId: constants.auth.CLIENT_ID, 
        authority:  constants.auth.SIGNIN_AUTHORITY, 
        knownAuthorities: [constants.auth.KNOWN_AUTHORITIES], 
        redirectUri: constants.auth.POST_LOGIN_REDIRECT_URI,
        postLogoutRedirectUri: constants.auth.POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: constants.auth.CACHE_LOCATION,
        storeAuthStateInCookie: false, 
    },
    system: {
        loggerOptions,
        tokenRenewalOffsetSeconds,
    },
};

const loginRequest = {
    scopes: ["openid", constants.auth.B2C_SCOPES],
};

const signUpRequest = {
    ...loginRequest,
    authority: constants.auth.SIGNUP_AUTHORITY,
    extraQueryParameters : {},
};

const tokenRequest = {
    scopes: [constants.auth.B2C_SCOPES], 
    forceRefresh: false, 
};

const msalInstance = msalInstance || new msal.PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise()
    .then(response => {
        wasLastTokenRetrievalSuccessful = true;
        handleResponse(response);
    })
    .catch(error => {
        console.log(error);
        handleSignInError();
    });

function selectAccount() {
    const currentAccounts = msalInstance.getAllAccounts();
    if (!currentAccounts || currentAccounts.length < 1) {
        console.log("No Accounts");
        inProgress = true;
        return;
    } else if (currentAccounts.length > 1) {
        console.log("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
    }
}

function handleResponse(response, isRenew = false) {
    if (!isRenew && inProgress && response) { // is a new sign in
        isNewSignIn = true;
    }
    inProgress = false;

    if (response !== null) {
        if (response.idToken && response.idToken !== "") {
            token = response.idToken;
        }
    } 
        
    if (response === null && signedIn) { 
        return;
    } else {
        selectAccount(); 
        if (!isRenew) {
            authActions.pageReady(isNewSignIn);
            isNewSignIn = false;
        }
    } 
}

function handleSignInError() {
    authActions.signInError();
}

function checkForToken() {
    selectAccount();
    if (accountId && !token) {
        signedIn = true;
        getTokenRedirect(tokenRequest);
    } else {
        signedIn = false;
    }   
}

function getHomeAccount() {
    return msalInstance.getAccountByHomeId(accountId);
}

function login() {
    msalInstance.loginRedirect(loginRequest);
}

function logOut() {
    const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accountId),
    };
    
    msalInstance.logout(logoutRequest);
}

function signUp(token = null) {
    signUpRequest.extraQueryParameters = {
        id_token_hint: token,
        prompt: 'login',
    };
    msalInstance.loginRedirect(signUpRequest);
}

function getTokenRedirect(request, isRenew = false) {
    request.account = msalInstance.getAccountByHomeId(accountId);    
    return msalInstance.acquireTokenSilent(request)
        .then((response) => {
            if (!response.idToken || response.idToken === "") {
                throw new msal.InteractionRequiredAuthError;
            } 
            wasLastTokenRetrievalSuccessful = true;
            return handleResponse(response, isRenew);
        })
        .catch(error => {
            if (error instanceof msal.InteractionRequiredAuthError || error instanceof msal.BrowserAuthError) {
                return msalInstance.acquireTokenRedirect(request);
            } else {
                wasLastTokenRetrievalSuccessful = false;
                console.log(error);  
            }
        });
}

function getToken() {
    if (!wasLastTokenRetrievalSuccessful) {            
        getTokenRedirect(tokenRequest, true);
    } else {
        try {
            return token;
        } catch (error) {
            console.log(error);
        }
    }
}

setInterval(function () {
    getTokenRedirect(tokenRequest, true);
}.bind(this), tokenRenewalOffsetSeconds * 1000);


export { msalInstance, login, logOut, signUp, getTokenRedirect, getHomeAccount, checkForToken, getToken };
