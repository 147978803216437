'use strict';

var ScrollManager = function (callback, isFull) {
    this.callback = callback;
    this.isFull = isFull;
    this.navTogglePosition = 60;
};

ScrollManager.prototype.start = function () {
    window.onscroll = function () {
        const yOffset = window.pageYOffset;
        if (yOffset < this.navTogglePosition) {
            if (!this.isFull) {
                this.callback(true);
            }
            this.isFull = true;
        } else {
            if (this.isFull) {
                this.callback(false);
            }
            this.isFull = false;
        }
    }.bind(this);
};

if (typeof module !== 'undefined' && module.exports) {
    module.exports = ScrollManager;
}