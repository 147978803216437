'use strict';

import React from 'react';


class ErrorContent extends React.Component {

    render() {
        return (
            <div>
                <h2>Error</h2>
                <p>An error has occured</p>
            </div>
        );
    }
}

export default ErrorContent; 
