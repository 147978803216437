'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import 'styles/StatusBar.scss';


const propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.bool,
    info: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    plain: PropTypes.bool,
    noPadding: PropTypes.bool,
    contentDangerouslySetInnerHTML: PropTypes.bool,
    titleDangerouslySetInnerHTML: PropTypes.bool,
    icon: PropTypes.string,
};

const defaultProps = {
    contentDangerouslySetInnerHTML: false,
    titleDangerouslySetInnerHTML: false,
};

class StatusBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const classes = classNames(
            'status-bar', this.props.className ? this.props.className : "", {
                "error": this.props.error,
                "info": this.props.info,
                "warning": this.props.warning,
                "success": this.props.success,
                "plain": this.props.plain,
                "no-padding": this.props.noPadding,
            }
        );

        
        let defaultIconClass = "icon-information-small";

        if (this.props.error) {
            defaultIconClass = "icon-error-small";
        } else if (this.props.warning) {
            defaultIconClass = "icon-warning-small";
        }

        const iconClass = this.props.icon ?? defaultIconClass; 

        let title;
        if (this.props.title) {
            if (this.props.titleDangerouslySetInnerHTML) {
                title = <div className="sb-title" dangerouslySetInnerHTML={{__html:this.props.title}}></div>;
            } else {
                title = <div className="sb-title">{this.props.title}</div>;
            }
        }

        let content;
        if (this.props.content) {
            if (this.props.contentDangerouslySetInnerHTML) {
                content = <div className="sb-content" dangerouslySetInnerHTML={{__html:this.props.content}}></div>;
            } else {
                content = <div className="sb-content">{this.props.content}</div>;
            }
        }

        return (
            <div id={this.props.id ? this.props.id : ""} className={classes}>
                <div className="status-bar-icon">
                    <i className={iconClass} />
                </div>
                <div className="status-bar-info">
                    {title}
                    {content}
                </div>
            </div>
        );
    }
}

StatusBar.propTypes = propTypes;
StatusBar.defaultProps = defaultProps;

export default StatusBar; 

