'use strict';

import React from 'react';
import Formsy from 'formsy-react';
import modalActions from 'actions/ModalActionCreators';
import AvatarEditor from 'components/base/form-components/AvatarEditor';
import ModalHeader from 'components/base/ModalHeader';
import ModalBody from 'components/base/ModalBody';
import ModalFooter from 'components/base/ModalFooter';
import personActions from 'actions/PersonActionCreators';
import personStore from 'stores/PersonStore';
import utils from 'services/utils';
import personUtils from 'services/people/personUtils';
import StatusBar from  'components/base/StatusBar';
import appConstants from 'constants/appConstants';

import 'styles/AvatarEdit.scss';

class AvatarEdit extends React.Component {
    constructor(props) {
        super(props);
        this.onPersonAvatarUpdate = this.onPersonAvatarUpdate.bind(this);
        this.onPersonChange = this.onPersonChange.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.handlePicChange = this.handlePicChange.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleZoomOutClick = this.handleZoomOutClick.bind(this);
        this.handleZoomInClick = this.handleZoomInClick.bind(this);
        this.submitValid = this.submitValid.bind(this);
        this.state = {
            scale: 1,
            picture: this.props.avatarExists ? "data:image/jpeg;base64," + this.props.profile.PersonAvatars[0].Picture : this.props.picture,
        };
    }

    componentDidMount() {
        personStore.addSinglePersonAvatarUpdateListener(this.onPersonAvatarUpdate);
        personStore.addChangeListener(this.onPersonChange);
    }

    componentWillUnmount() {
        personStore.removeSinglePersonAvatarUpdateListener(this.onPersonAvatarUpdate);
        personStore.removeChangeListener(this.onPersonChange);
    }

    onPersonChange() {
        var _state = personStore.getPerson();

        if (!_state.loading && this.state.checkUpdate) {
            setTimeout(function () {
                modalActions.hideModal();
            }.bind(this), 0);
        }
    }

    onPersonAvatarUpdate() {
        var _state = personStore.getPerson();
        var personAvatar = _state.person.PersonAvatar;
      
        this.setState({
            error: null
        });

        if (!personAvatar.loading) {
            
            if (personAvatar.error) {
                this.setState({error: personAvatar.error !== appConstants.request.TIMEOUT ? personAvatar.error : null});
            } else {
                this.setState({checkUpdate: true}, 
                    function () {
                        setTimeout(function () {
                            personActions.getSinglePerson(this.props.profile.Id);
                        }.bind(this), 0);
                    }
                );
                return;
            }
        } 

        setTimeout(function () {
            modalActions.loading(personAvatar.loading);
        }, 0);
    }

    handleZoomOutClick(event) {
        utils.preventDefault(event);
        var newScale = this.state.scale - 0.05;
        if (newScale >= 1) {
            this.setState({scale: newScale});
        }
    }

    handleZoomInClick(event) {
        utils.preventDefault(event);
        var newScale = this.state.scale + 0.05;
        if (newScale <= 3) {
            this.setState({scale: newScale});
        }
    }

    handleScale() {
        var scale = parseFloat(this.refs.scale.value);
        this.setState({scale});
    }


    submitValid() {
        if (!this.state.picture) {
            personActions.deleteAvatar(this.props.profile.Id);
        } else {
            var img = this.refs.avatar.getImage().toDataURL("image/jpeg",0.7).replace("data:image/jpeg;base64,", "");
            var avatar = {Picture: img};

            personActions.updateAvatar(this.props.profile.Id, avatar);
        }

        
        return;
    }

    handleDeleteButtonClick(event) {
        utils.preventDefault(event);
        
        this.setState({
            picture: null,
            error: null
        });

        return;
    }

    handlePicChange(event) {
        var files = event.target.files;
        var file = files[0];

        if (files && file) {
            if (file.type === "image/png" ||
                file.type === "image/gif" ||
                file.type === "image/jpeg") {
                var reader = new FileReader();
                var _this = this;
                reader.onload = function (readerEvt) {
                    _this.setState({
                        picture: readerEvt.target.result,
                    });
                };

                reader.readAsDataURL(file);
            } else {
                return;
            }
        }
    }

    render() {
        var avatarComponent;
        if (this.state.picture) {
            avatarComponent = (
                <div>
                    <AvatarEditor
                        ref="avatar"
                        image={this.state.picture}
                        width={260}
                        height={260}
                        border={0}
                        borderRadius={130}
                        color={[255,255,255,0.95]}
                        scale={parseFloat(this.state.scale)}
                    />
                  
                </div>
            );
        } else {
            var styles = {};
            var name = '';
            var firstName = this.props.profile.PreferredName || this.props.profile.FirstName || '';
            var lastName = this.props.profile.LastName || '';

            name = firstName.charAt(0) + lastName.charAt(0);
            styles.backgroundColor = personUtils.getAvatarColour(this.props.profile.Id);

            avatarComponent = <span className="avatar-name" style={styles}>{name}</span>; 
        }

        return (
            <Formsy ref="form" onValidSubmit={this.submitValid} autoComplete="off">
                <ModalHeader closeModal={this.props.closeModal}>Profile Photo</ModalHeader>

                <ModalBody>
                    { this.state.error ? <StatusBar content={this.state.error} error /> : null }
                    <div id="avatar-edit" className="edit-wrapper">
                        <label htmlFor="EditAvatarFileInput" className="button-label">
                            <input id="EditAvatarFileInput" type="file" accept="image/*" className="button " style={{display: "none"}} onChange={this.handlePicChange} />
                            <span className="button button--no-horizontal-pad">
                                Upload Photo
                            </span>
                        </label>
                        <span className="margin" />
                        <button disabled={this.state.picture === undefined || this.state.picture === null} className="button button--danger button--no-horizontal-pad" onClick={this.handleDeleteButtonClick}>Clear Photo</button>
                        {avatarComponent}
                        <button disabled={this.state.picture === undefined || this.state.picture === null} className="button" onClick={this.handleZoomOutClick}>Zoom Out</button>
                        <span className="margin" />
                        <button disabled={this.state.picture === undefined || this.state.picture === null} className="button" onClick={this.handleZoomInClick}>Zoom In</button>
                            
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="modal-footer">
                        <button type="submit" className="button--primary" formNoValidate>Save</button>
                    </div>
                </ModalFooter>
            </Formsy>
        );
    }
}

export default AvatarEdit; 