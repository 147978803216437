'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import modalActions from 'actions/ModalActionCreators';
import AvatarEdit from 'components/base/AvatarEdit';
import AvatarFileInput from 'components/base/form-components/AvatarFileInput';
import personUtils from 'services/people/personUtils';
import classNames from 'classnames';
import 'services/utils';

import 'styles/Avatar.scss';

class Avatar extends React.Component {
    static defaultProps = {
        firstNameFallback: '',
        lastNameFallback: '',
        iconOverlay: false,
    };

    static propTypes = {
        profile: PropTypes.object,
        firstNameFallback: PropTypes.string,
        lastNameFallback: PropTypes.string,
        avatarIdFallback: PropTypes.number,
        iconOverlay: PropTypes.bool,
    };

    handleOpenAvatarEdit = () => {
        var content = (
            <AvatarEdit
                {...this.props}
                avatarExists={this.props.profile.PersonAvatars && this.props.profile.PersonAvatars[0] && this.props.profile.PersonAvatars[0].Picture}
            />
        );

        modalActions.showModal(content);
    };

    render() {
        var styles = {};
        var name = '';
        var avatarImage;
        if (this.props.allowEdit) {
            styles.cursor = "pointer";
        }

        if (this.props.profile) {
            if (this.props.profile.PersonAvatars && this.props.profile.PersonAvatars[0] && this.props.profile.PersonAvatars[0].Picture) {
                var imgString = "data:image/jpeg;base64," + this.props.profile.PersonAvatars[0].Picture;
                avatarImage = <img src={imgString} />;
            } else {
                var firstName = this.props.profile.PreferredName || this.props.profile.FirstName || '';
                var lastName = this.props.profile.LastName || '';

                name = firstName.charAt(0) + lastName.charAt(0);
                styles.backgroundColor = personUtils.getAvatarColour(this.props.profile.Id);
            }
        } else {
            name = this.props.firstNameFallback.charAt(0) + this.props.lastNameFallback.charAt(0);
            if (this.props.avatarIdFallback) {
                styles.backgroundColor = personUtils.getAvatarColour(this.props.avatarIdFallback);
            }
        }

        var returnContent;

        if (this.props.allowEdit) {
            if (avatarImage) {
                returnContent = <span className="avatar-edit-group"><span className={this.props.className + " avatar-img"} onClick={this.handleOpenAvatarEdit} style={styles}>{avatarImage}</span><i className="icon-edit" onClick={this.handleOpenAvatarEdit}></i></span>;
            } else {
                returnContent = <label htmlFor="AvatarFileInput"><AvatarFileInput {...this.props} /><span className="avatar-edit-group"><span className={this.props.className + " avatar"} style={styles}>{name}</span><i className="icon-add"></i></span></label>;
            }
        } else {

            var classes = classNames(
                this.props.className,
                'avatar', 
                {
                    'avatar-icon': this.props.iconOverlay,
                    'avatar-img': avatarImage,
                }
            );

            if (avatarImage) {
                returnContent = (
                    <span className={classes} style={styles}>
                        {avatarImage}
                    </span>
                );
            } else {
                returnContent = (
                    <span className={classes} style={styles}>
                        {name}
                    </span>
                );  
            }
             
        }


        return (
            returnContent  
        );
    }
}

export default Avatar;