'use strict';
import React from 'react';
import Spinner from 'components/base/Spinner';
import constants from 'constants/appConstants';

import 'styles/ModalLoader.scss';

class ModalLoader extends React.Component {
    render() {

        var styles = {
            loader: {
                display: this.props.loading ? 'block' : 'none',
            },
        };

        return (

            <div id="modal-loader" style={styles.loader}>
                <div id="modal-loader-mask"></div>
                <Spinner
                    display={this.props.loading}
                    id={constants.spinner_options.modal.id}
                    isMainSpinner
                />
            </div>

        );
    }
}

export default ModalLoader; 
