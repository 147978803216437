'use strict';

import Dispatcher from 'dispatcher/TngAppDispatcher';

import utils from 'services/utils';
import constants from 'constants/appConstants';
import AnalyticsManager from 'services/analyticsManager';

var ModalActionCreators = {

    clearModal() {
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.CLOSE,
        });
    },
    showModal(content, className) {
        AnalyticsManager.modalView(content.type.name);
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.OPEN,
            data: content,
            className,
        });
    },
    disableEscapeButton() {
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.DISABLE_ESCAPE,
        });
    },
    enableEscapeButton() {
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.ENABLE_ESCAPE,
        });
    },
    hideModal(event) {
        // Send null and Analytics will assume its a modal window. GABS
        AnalyticsManager.event(
            null,
            AnalyticsManager.constants.actions.CLOSE_MODAL);
        
        if (event) {
            utils.preventDefault(event);
        }
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.CLOSE,
        });
    },
    loading(loading) {
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.LOADING,
            loading,
        });
    },
    refreshModal() {
        Dispatcher.handleAction({
            actionType: constants.actions.MODAL.REFRESH,
        });
    },
};

export default ModalActionCreators; 
