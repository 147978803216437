'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import modalActions from 'actions/ModalActionCreators';

import ModalHeader from 'components/base/ModalHeader';
import ModalBody from 'components/base/ModalBody';
import ModalFooter from 'components/base/ModalFooter';

import classNames from 'classnames';

import 'styles/WizardModal.scss';

const propTypes = {
    sections: PropTypes.array,
    showCloseButton: PropTypes.bool,
    header: PropTypes.string,
    nextButtonLabel: PropTypes.string,
    finalButtonLabel: PropTypes.string,
    onCloseCallback: PropTypes.func,
};

const defaultProps = {
    showCloseButton: true,
    nextButtonLabel: "Next",
    finalButtonLabel: "OK",
};

class WizardModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSectionIndex: 1,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    moveToStep = (sectionNo) => {
        setTimeout(function () {
            this.setState({
                activeSectionIndex: sectionNo,
            }, () => {
                modalActions.refreshModal();
            });
        }.bind(this), 0);
    };

    handleCloseWindow = () => {
        if (this.props.onCloseCallback) {
            this.props.onCloseCallback();
        } else {
            modalActions.hideModal();
        }
    }

    render() {
        const currentSection = this.props.sections[this.state.activeSectionIndex - 1];
        const isLastSection = this.state.activeSectionIndex === this.props.sections.length;
        const dots = [];

        for (var i = 0; i < this.props.sections.length; i++) {
            const classes = classNames(
                'mw-dot', {
                    active: this.state.activeSectionIndex === i + 1,
                }
            );

            dots.push(
                <span
                    key={"dot-" + (i + 1)}
                    onClick={this.moveToStep.bind(this, i + 1)}
                    className={classes}
                >
                </span>
            );
        }

        const classes = classNames('modal-wizard', {
            'mw-has-header': this.props.header, 
            'mw-has-close': this.props.showCloseButton,
        }); 

        const wizardHeader = this.props.header;

        return (
            <div className={classes}>
                {wizardHeader ? (
                    <div className="mw-header">
                        <div>
                            {wizardHeader}
                        </div>

                        {this.props.showCloseButton ? (
                            <button
                                id="button-modal-close"
                                className="button--plain button--square close-button"
                                onClick={this.handleCloseWindow}
                            >
                                <i className="icon-close" />
                            </button>       
                        ) : ''}

                    </div>
                ) : null}
                {currentSection.heading ? (
                    <ModalHeader
                        showCloseButton={!this.props.header && this.props.showCloseButton}
                        className={'mw-section-header'}
                    >
                        {currentSection.heading}
                    </ModalHeader>
                ) : null}
                <ModalBody className="mw-body">
                    {currentSection.content}
                </ModalBody>
                <div className="mw-preview-dots">
                    {dots}
                </div>
                <ModalFooter>
                    <div className="modal-footer">
                        {!isLastSection ? (
                            <button className="button--primary" onClick={this.moveToStep.bind(this, this.state.activeSectionIndex + 1)}>{this.props.nextButtonLabel}</button>
                        ) : (
                            <button className="button--primary" onClick={this.handleCloseWindow}>{this.props.finalButtonLabel}</button>
                        )}
                    </div>
                </ModalFooter>
            </div>
        );
    }
}

WizardModal.propTypes = propTypes;
WizardModal.defaultProps = defaultProps;

export default WizardModal;
