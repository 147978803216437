'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class ConditionalDisplay extends React.Component {
    render() {
        if (this.props.test) {
            return (
                <div id={this.props.id} className={this.props.className} style={this.props.style}>
                    {this.props.children}
                </div>
            );
        } else {
            return null;
        }
    }
}

ConditionalDisplay.propTypes = {
    test: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

ConditionalDisplay.defaultProps = {
    test: false,
};

export default ConditionalDisplay; 
