'use strict';

import React from 'react';

import appConstants from 'constants/appConstants';

class ErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            errorInfo: null,
        };
    }
  
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }
  
    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h2>{appConstants.error.GENERIC_ERROR}</h2>
                    <p>
                        {appConstants.error.GENERIC_ERROR_DESC}
                    </p>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }  
}

export default ErrorBoundary; 
