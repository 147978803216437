'use strict';

import React from 'react';
import modalActions from 'actions/ModalActionCreators';
import classNames from 'classnames';

const defaultProps = {
    showCloseButton: true,
};

const ModalHeader = (props) => {

    const handleCloseButtonClick = (event) => {
        modalActions.hideModal(event);
    };

    const classes = classNames('mc-header', props.className);

    return (
        <div className={classes}>
            <h4 className="mc-header-heading">{props.children}</h4>
            {props.showCloseButton ? (
                <button
                    id="button-modal-close"
                    className="button--plain button--square close-button"
                    onClick={handleCloseButtonClick}
                >
                    <i className="icon-close" />
                </button>       
            ) : ''}
        </div>
    );
};

ModalHeader.defaultProps = defaultProps;

export default ModalHeader; 