'use strict';

import React from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';

import constants from 'constants/appConstants';
import _ from 'underscore';

import Spinner from 'components/base/Spinner';

class TextInput extends React.Component {

    state = {}

    componentDidMount() {
        if (this.props.getNodeCallback) {
            this.props.getNodeCallback(this.refs[this.props.name]);
        }
    }

    changeValue = (event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        this.props.setValue(event.currentTarget.value);
        if (this.props.onChangeCallback) {
            if (typeof (this.props.onChangeCallback) === "function") {
                this.props.onChangeCallback(event);
            } else if (typeof (this.props.onChangeCallback) === "object") {
                _.each(this.props.onChangeCallback, function (fn, i) {
                    fn(event);
                });
            }
        }
    }

    focusHandler = (event) => {
        if (this.props.onFocusCallback) {
            if (typeof (this.props.onFocusCallback) === "function") {
                this.props.onFocusCallback(event);
            } else if (typeof (this.props.onFocusCallback) === "object") {
                _.each(this.props.onFocusCallback, function (fn, i) {
                    fn(event);
                });
            }
        }

        this.setState({ isFocused: true });
    }

    blurHandler = (event) => {
        if (this.props.onBlurCallback) {
            if (typeof (this.props.onBlurCallback) === "function") {
                this.props.onBlurCallback(event);
            } else if (typeof (this.props.onBlurCallback) === "object") {
                _.each(this.props.onBlurCallback, function (fn, i) {
                    fn(event);
                });
            }
        }

        this.setState({ isFocused: false });
    }

    clickHandler = (event) => {
        if (this.props.onClickCallback) {
            if (typeof (this.props.onClickCallback) === "function") {
                this.props.onClickCallback(event);
            } else if (typeof (this.props.onClickCallback) === "object") {
                _.each(this.props.onClickCallback, function (fn, i) {
                    fn(event);
                });
            }
        }
    }

    keyDownHandler = (event) => {
        if (this.props.onKeyDownCallback) {
            if (typeof (this.props.onKeyDownCallback) === "function") {
                this.props.onKeyDownCallback(event);
            } else if (typeof (this.props.onKeyDownCallback) === "object") {
                _.each(this.props.onKeyDownCallback, function (fn, i) {
                    fn(event);
                });
            }
        }
    }

    buttonHandler = (event) => {
        if (this.props.onButtonCallback) {
            if (typeof (this.props.onButtonCallback) === "function") {
                this.props.onButtonCallback(event);
            } else if (typeof (this.props.onButtonCallback) === "object") {
                _.each(this.props.onButtonCallback, function (fn, i) {
                    fn(event);
                });
            }
        }
    }

    render() {

        var classes = classNames(
            'fc-input', this.props.className, {
                'required': this.props.showRequired() && !this.props.isPristine(),
                'error': !(this.props.showRequired() && !this.props.isPristine()) && this.props.showError(),
                'server-error': this.props.serverMessage,
                'disabled': this.props.disabled,
                'borderless': this.props.readOnly,
            }
        );

        var inputClasses = classNames(
            this.props.inputClassName, {
                used: this.props.getValue(),
            }
        );

        const signClasses = classNames(
            "sign-class", this.props.signClass
        );

        var requiredElement;
        var validationError;
        var serverError;
        var extraInfoComponent;

        if (this.props.isRequired()) {
            requiredElement = <span className="required-msg validation-error">{constants.error.FORMS.FORM_FIELD_REQUIRED}</span>;
        }

        validationError = this.props.getErrorMessage();
        serverError = this.props.serverMessage;
        extraInfoComponent = this.props.extraInfoComponent;
        //}

        var spinnerElement;

        if (this.props.loading) {
            spinnerElement = (
                <div className="input-spinner">
                    <Spinner
                        id={this.props.name.replace(":", "") + "-spinner"}
                        display={this.props.loading}
                    />
                </div>
            );
        }

        return (
            <div id={'form-element-{0}'.format(this.props.name)} className={classes}>
                {spinnerElement}
                <div className={signClasses} data-content={this.props.signContent}>
                    <input
                        ref={this.props.name}
                        className={inputClasses}
                        id={this.props.name}
                        name={this.props.name}
                        type={this.props.type || "text"}
                        onChange={this.changeValue}
                        onFocus={this.focusHandler}
                        onBlur={this.blurHandler}
                        onClick={this.clickHandler}
                        onKeyDown={this.keyDownHandler}
                        maxLength={this.props.maxLength}
                        value={this.props.getValue() || ''}
                        autoComplete={this.props.autocomplete || "on"}
                        disabled={this.props.disabled || this.props.readOnly}
                        placeholder={this.props.placeholder}
                        autoFocus={this.props.autoFocus}
                    />
                </div>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                <div>{extraInfoComponent}</div>
                <span className="validation-error">{validationError}</span>
                <span className="server-error-msg validation-error">{serverError}</span>
                {this.props.isSearch ? <button className="button--plain button--square" onClick={this.buttonHandler}><i className={this.props.buttonIconName}></i></button> : ""}
                {requiredElement}
            </div>
        );
    }
}

export default withFormsy(TextInput); 
