'use strict';

import { EventEmitter } from 'events';
import assign from 'object-assign';
import TngAppDispatcher from '../dispatcher/TngAppDispatcher';

import appConstants from 'constants/appConstants';
import _ from 'underscore';
import processAction from 'services/storeUtils';

var _approvals = null;
var _otherApprovals = null;
var _approvalCount = null;
var _approvalActionResponseQueue = [];
var _loading;
var _error;

var approvalsLoaded = function (response) {
    _approvals = null;
    _loading = false;
    _error = null;

    if (response === appConstants.request.PENDING) {
        _loading = true;
    } else if (response === appConstants.request.TIMEOUT) {
        _error = 'TIMEOUT';
    } else if (response === appConstants.request.ERROR) {
        _error = 'ERROR';
    } else {
        _approvals = response.body.Items;

        //_approvals.leaveCount = _.where(response.body.Items, {WorkflowType: appConstants.ENUMS.WORKFLOW_TYPES.LEAVE}).length;
        //_approvals.profileCount = _.where(response.body.Items, {WorkflowType: appConstants.ENUMS.WORKFLOW_TYPES.PERSONAL_CHANGES}).length;
    }
};

var otherApprovalsLoaded = function (response) {
    _otherApprovals = null;
    _loading = false;
    _error = null;

    if (response === appConstants.request.PENDING) {
        _loading = true;
    } else if (response === appConstants.request.TIMEOUT) {
        _error = 'TIMEOUT';
    } else if (response === appConstants.request.ERROR) {
        _error = 'ERROR';
    } else {
        _otherApprovals = response.body.Items;
    }
};

var approvalAction = function (response) {

    var workflowItemId = response.queryParams &&  response.queryParams.ApprovalItem && response.queryParams.ApprovalItem.WorkflowItemId ? response.queryParams.ApprovalItem.WorkflowItemId : -1;

    /* See if WorkflowID exists */
    var approvalResponse = _.findWhere(_approvalActionResponseQueue, {workflowItemId});

    /* Delete if present */
    if (approvalResponse) {
        _approvalActionResponseQueue.splice(_.indexOf(_approvalActionResponseQueue, approvalResponse), 1); 
    }

    /* Insert new response */
    approvalResponse = {
        workflowItemId,
        actionType: response.actionType,
        data: response.data,
    };

    var actions = processAction(response, function (responseBody) {
        approvalResponse.data = responseBody;
    });

    approvalResponse.loading = actions.loading; 
    approvalResponse.error = actions.error;

    _approvalActionResponseQueue.push(approvalResponse);

};

var approvalCountLoaded = function (response) {

    if (!_approvalCount) {
        _approvalCount = {personalChanges:null, leave:null};
    }

    _loading = false;
    _error = null;

    if (response.data === appConstants.request.PENDING) {
        _loading = true;
    } else if (response.data === appConstants.request.TIMEOUT) {
        _error = 'TIMEOUT';
    } else if (response.data === appConstants.request.ERROR) {
        _error = 'ERROR';
    } else {
        if (response.queryParams.WorkflowType === appConstants.ENUMS.WORKFLOW_TYPES.PERSONAL_CHANGES &&  _approvalCount.personalChanges !== response.data.body.Count) {
            _approvalCount.personalChanges = response.data.body.Count;
            _approvals = null;
        } else if (response.queryParams.WorkflowType === appConstants.ENUMS.WORKFLOW_TYPES.LEAVE && _approvalCount.leave !== response.data.body.Count) {
            _approvalCount.leave = response.data.body.Count;
            _approvals = null;
        }
    }

};

var AprovalsStore = assign({}, EventEmitter.prototype, {
    addChangeListener(callback) {
        this.on(appConstants.events.CHANGE, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(appConstants.events.CHANGE, callback);
    },
    addOtherApprovalsListener(callback) {
        this.on(appConstants.actions.APPROVALS.GET_OTHER_APPROVALS, callback);
    },
    removeOtherApprovalsListener(callback) {
        this.removeListener(appConstants.actions.APPROVALS.GET_OTHER_APPROVALS, callback);
    },
    addApprovalActionResponseListener(callback) {
        this.on(appConstants.actions.APPROVALS.SET_APPROVAL_ACTION, callback);
    },
    removeApprovalActionResponseListener(callback) {
        this.removeListener(appConstants.actions.APPROVALS.SET_APPROVAL_ACTION, callback);
    },
    getApprovals() {
        return {
            approvals: _approvals,
            loading: _loading,
            error: _error,
        };
    },
    getOtherApprovals() {
        return {
            otherApprovals: _otherApprovals,
            loading: _loading,
            error: _error,
        };
    },
    getApprovalCount() {
        return {
            approvalCount: _approvalCount,
            loading: _loading,
            error: _error,
        };
    },
    getApprovalActionResponseQueue() {
        return {
            approvalActionResponseQueue: _approvalActionResponseQueue,
        };
    },
    setApprovalCount(leave, personalChanges) {
        _approvalCount = {leave, personalChanges};
    },
    resetApprovalActionResponseQueue() {
        _approvalActionResponseQueue = [];
    },
});

AprovalsStore.dispatchToken = TngAppDispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {
    case appConstants.actions.APPROVALS.GET_APPROVALS:
        approvalsLoaded(action.data);
        AprovalsStore.emit(appConstants.events.CHANGE);
        break;

    case appConstants.actions.APPROVALS.GET_OTHER_APPROVALS:
        otherApprovalsLoaded(action.data);
        AprovalsStore.emit(appConstants.actions.APPROVALS.GET_OTHER_APPROVALS);
        break;

    case appConstants.actions.APPROVALS.APPROVE:
        approvalAction(action);
        AprovalsStore.emit(appConstants.actions.APPROVALS.SET_APPROVAL_ACTION);
        break;

    case appConstants.actions.APPROVALS.DECLINE:
        approvalAction(action);
        AprovalsStore.emit(appConstants.actions.APPROVALS.SET_APPROVAL_ACTION);
        break;

    case appConstants.actions.APPROVALS.GET_APPROVAL_COUNT:
        approvalCountLoaded(action);
        AprovalsStore.emit(appConstants.events.CHANGE);
        break;

    default:
        return true;
    }

    return true;

});

export default AprovalsStore; 
